import {useState, useEffect} from 'react';


export default function useAgeGate(store, waitUntilTrue) {
  const [age, setAge] = useState();
  const [ageLoading, setAgeLoading] = useState(true);
  const [ageReady, setAgeReady] = useState(false);

  useEffect(() => {
    const waitUntilTrueNotPassed = waitUntilTrue === undefined;
    if (waitUntilTrue || waitUntilTrueNotPassed) {
      store.get('age')
        .then(function({data, error}) {
          if (data) setAge(data)
          setAgeReady(true)
          setAgeLoading(false)
          if (error) return error
        })
    }
  }, [waitUntilTrue]);

  useEffect(() => {
    if (ageLoading) return;
		// if (!(age === 'atLeast21')) navigate('/age');
	}, [age, ageLoading]);

  function handleAtLeast21() {
    setAge('atLeast21')
    store.set('age', 'atLeast21')
      .then(function({data, error}) {}) // NOTE: Handle error
  }

  function handleNot21() {
    setAge('not21')
    store.remove('age')
      .then(function({data, error}) {})// NOTE: Handle error
  }

  return {age, ageLoading, ageReady, handleAtLeast21, handleNot21};
}