import {useContext} from "react";
import {Svg, Circle, Path} from 'react-native-svg';

import Context from "../context.jsx";


export default function SpiritsIcon({size, color, backgroundColor, style}) {
  const {tw} = useContext(Context);

  return <Svg xmlns="http://www.w3.org/2000/svg"
    style={tw.style(style ? style : null, {height: size, width: size})}
    fill={backgroundColor}
    viewBox="0 0 1026 1026"
    strokeWidth={1.5}
    stroke={backgroundColor}>

    <Circle fill={color}
      cx='50%'
      cy='50%'
      r='47%' />

    <Path transform="matrix(1 0 0 -1 0 963)"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M512 934.4c268.631 0 486.4-217.769 486.4-486.4s-217.769-486.4-486.4-486.4c-268.631 0-486.4 217.769-486.4 486.4s217.769 486.4 486.4 486.4zM542.377 640h-60.739l-25.702-151.017c-8.439-4.146-9.616-7.006-23.499-18.431v0l-10.872-8.622-17.822-13.491c-29.957-22.493-69.64-44.51-70.944-50.593v0-333.847h358.415v333.847l-0.411 0.927c-4.053 6.477-35.101 24.315-62.157 43.555v0l-8.375 6.111-17.822 13.491-7.695 6.039-6.751 5.568c-10.592 8.985-12.275 11.687-19.925 15.445v0l-25.702 151.017zM542.3 704h-60.842l0.077-51.2h60.928l-0.164 51.2z" />
  </Svg>;
}
