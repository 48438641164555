import {useContext, useState} from "react";
import {View, Text, TextInput} from "react-native";
import {ajaxRequest, emailValid} from "js-utilities";

import Context from "./context.jsx";

import Page from './ui/page.jsx';
import Button from "./ui/button.jsx";
import LoadingSpinner from "./ui/loadingSpinner.jsx";


export default function ResetPassword() {
  const {tw, config, navigate, currentGradient, displayStatusMessage} = useContext(Context)

  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)

  function validateEmail(email) {
    if (emailValid(email)) return true
    else return displayStatusMessage({ type: 'error', message: 'Invalid Email', details: 'Please enter a valid email address' })
  }

  function handleSubmit() {
    if (!validateEmail(email)) return;
    setLoading(true)

    ajaxRequest('POST', `${config.apiEndpoint}/auth/password?email=${email}&redirect_url=${config.resetPasswordRedirectUrl}&reactnative=true`)
      .then(function() {
        setLoading(false)
        displayStatusMessage({
          type: 'success',
          message: 'Email Sent',
          details: 'Check your email for a link to reset your password'})
        navigate('/');
      }).catch(function() {
        setLoading(false)
        displayStatusMessage({
          type: 'success',
          message: 'Email Sent',
          details: 'Check your email for a link to reset your password'})
        navigate('/')
      })
  }

  return <Page header='Reset Password'>
    <View style={tw`w-full`}>
      <Text style={tw`mb-xs text-lg text-dark-dark font-primaryMedium font-spacing`}>Email</Text>
      <TextInput style={tw`mb-base p-xs rounded-sm border border-dark text-lg font-primary font-spacing outline-none`}
        onChangeText={email => setEmail(email)}
        value={email} />

      {loading ? <LoadingSpinner/> : <Button onPress={handleSubmit}
        color={currentGradient[0]}
        text='Send Email' />}
    </View>
  </Page>;
}
