import {Text, Pressable} from "react-native";

import {LinearGradient} from "expo-linear-gradient";
import {useContext} from "react";

import {pSBC} from "js-utilities";

import Context from "../context.jsx";


export default function Button({text, color, onPress, disabled, extraStyle, width}) {
  const {tw} = useContext(Context);

  return <Pressable onPress={onPress} 
    disabled={disabled}
    style={tw.style(`flex-row justify-center`, {...extraStyle})}>
    <LinearGradient colors={disabled ? [
        pSBC(-0.33, '#949494'), pSBC(-0.25, '#949494'),
        pSBC(-0.33, '#949494'), pSBC(-0.5, '#949494')
      ] : [
        pSBC(-0.33, color), pSBC(-0.25, color),
        pSBC(-0.33, color), pSBC(-0.5, color)
      ]}
      style={tw.style(`px-base py-xs rounded-sm shadow ${width ? `w-[${width}]`: ``}`)}
      locations={[0, 0.33, 0.67, 1]}
      start={{x: 0.12, y: 0.12}}
      end={{x: 0.97, y: 0.97}}>

      <Text style={tw.style(`text-center text-xl text-white font-primaryMedium font-spacing`, {
        textShadowColor: tw`text-light-light`.color,
        textShadowRadius: 1
      })}>{text}</Text>
    </LinearGradient>
  </Pressable>;
}
