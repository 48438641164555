import {MMKV} from 'react-native-mmkv'

const storage = new MMKV()

// TODO: when upgrading to react .71, upgrade MMKV and this use so that IDs
// can be passed to create separate keyspaces

export default function useMmkvStorage() {
  return storage
}
