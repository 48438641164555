import {useContext} from "react";
import {View, Text} from "react-native";

import Context from "./context.jsx";

import ConsumerBalance from "./consumerBalance";


export default function ConsumerBalancesList({title, consumerBalances, initiallyExpanded}) {
  const {tw} = useContext(Context);

  return <View style={tw`w-full`}>
    
    <View style={tw`mb-base py-sm border-b-2 border-primary-dark`}>
      <Text style={tw`text-4xl text-dark-dark font-primaryMedium leading-normal`}>{title}</Text>  
    </View>

    {consumerBalances.length === 0 ? <Text style={tw`text-center text-white`}>No Balance History</Text> : null}

    {consumerBalances.map((consumerBalance, index) => <ConsumerBalance key={`consumerBalance_${index}`}
      consumerBalance={consumerBalance}
      initiallyExpanded={initiallyExpanded}
      lastItem={index === consumerBalances.length - 1} />)}
  </View>;
}
