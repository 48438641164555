import {View, Text, Pressable, Image, ScrollView, PixelRatio} from "react-native";
import {useContext, useLayoutEffect, useState, memo, useMemo, useEffect} from "react";
import {LinearGradient} from "expo-linear-gradient";
import ImageWithProgress from 'react-native-image-progress';
import {Bar as ProgressIndicator} from 'react-native-progress';
import {BlurView} from "../assets/functions/objects";
import {decode as atob, encode as btoa} from 'base-64';

import {formatUtcDate, pSBC} from "js-utilities";

import {useParams, useLocation} from "./utilities/router.jsx";
import useMmkvStorage from "./hooks/useMmkvStorage.jsx";
import offerVibrantColors from '../assets/functions/offerVibrantColors.js';

import Context from "./context";
import RedeemForm from "./redeemForm.jsx";
import ChevronLeftIcon from "./icons/chevronLeftIcon";
import LoginCallout from "./loginCallout";
import VerifyCallout from "./verifyCallout";
import LoadingSpinner from "./ui/loadingSpinner";

const OfferPage = memo(function() {
  const maxImageSize = 1000

  const {tw,
    isIos,
    store,
    analyticsEvent,
    isDesktopWeb,
    headerHeight,
    contentHeight,
    swipableViewProps,
    offers,
    navigate,
    menuOpen,
    currentGradient,
    setCurrentGradient,
    setScrollTop,
    handleScroll,
    auth,
    consumerBalances,
    consumerBalancesReady,
    displayStatusMessage,
    offerImageContainerWidth, setOfferImageContainerWidth,
  } = useContext(Context)

  const defaultVibrantColors = [tw`text-blue`.color, tw`text-blue-dark`.color]

  const {id} = useParams()
  const imageAspectStorage = useMmkvStorage('image-aspects')
  const [vibrantColors, setVibrantColors] = useState(defaultVibrantColors)

  useEffect(function() {
    if (offers.length && !offer) {
      displayStatusMessage({ type: 'info', message: 'Offer Not Found', details: 'Offer is not valid or not available in your area' })
      navigate('/')}
  }, [offers, offer])

  useLayoutEffect(() => {
    setScrollTop(true)
    return () => setScrollTop(false);
  }, [])

  useLayoutEffect(() => {
    if (offer) {
      analyticsEvent('viewOffer', {
        name: offer.name,
        id: offer.id})
      setVibrantColors(offerVibrantColors(tw, offer, defaultVibrantColors))
      setCurrentGradient(offerVibrantColors(tw, offer, defaultVibrantColors))
    }
  }, [offer])

  const offer = useMemo(function() {
    return offers.find(offer => offer.id === Number(id))
  }, [offers, id])

  const scaledOfferImage = useMemo(function() {
    return offerImageForWidth(offerImageContainerWidth)},
  [offer, offerImageContainerWidth])

  const offerAspectRatio = useMemo(() => {
    if (offer) return imageAspectStorage.getNumber(`image-aspect-${offer.id}`) ?? 1.0
    else return 1.0
  }, [offer])

  function offerImageForWidth(width) {
    if (!offer) return;
    if (!width) return;

    const normalizedSize = 100 * Math.ceil(PixelRatio
      .getPixelSizeForLayoutSize(width) / 100)
    const clippedSize = Math.min(normalizedSize, maxImageSize)

    const imageApiUrl = 'https://images.bybe.io/'
    const offerImageFileName = offer.image_url
      .substring(offer.image_url.lastIndexOf('/') + 1)
    const requestPath = btoa(JSON.stringify({
      key: `offers/${offerImageFileName}`,
      edits: {
        resize: {
          width: clippedSize,
          height: clippedSize,
          fit: 'inside'
        }, trim: {
          threshold: 10 // number for color difference threshold
        }
      }
    }))
    return imageApiUrl + requestPath
  }

  const imageHeight = useMemo(function() {
    const maxImageHeight = tw`h-94`.height
    return Math.min(offerImageContainerWidth / offerAspectRatio, maxImageHeight)
  }, [offerImageContainerWidth])

  const limitReached = offer ? timesRedeemed >= offer?.per_consumer_limit : false
  const timesRedeemed = consumerBalancesReady && consumerBalances && offer ? consumerBalances.reduce((acc, balance) => {
    return acc + balance.redemptions.filter(redemption => redemption.offer.id === offer.id).length
  }, 0) : 0
  const redemmedText = timesRedeemed > 0 ? `Redemmed ${timesRedeemed} / ${offer?.per_consumer_limit}` : `Limit ${offer?.per_consumer_limit}`

	return <LinearGradient style={tw`h-[${headerHeight + contentHeight}px]`}
    colors={vibrantColors} start={{x: 0.10, y: 0.10}} end={{x: 0.9, y: 0.9}}
    {...(menuOpen ? {} : swipableViewProps)}>

    <LinearGradient colors={['rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0.36)']}
      style={tw`absolute w-full h-full`}
      start={{x: 0.5, y: 0.5}}
      end={{x: 1, y: 1}} />

    {offer ? <ScrollView style={tw`h-full`}
      contentContainerStyle={tw`items-center ${isIos ? 'pb-lg' : 'pb-sm'} pt-[${headerHeight + tw`pt-sm`.paddingTop}px]`}
      onScroll={handleScroll}
      scrollEventThrottle={50}>
      <Pressable style={tw`flex-row justify-start items-center w-full px-base mb-sm`}
        onPress={() => navigate('/offers')}>
        <ChevronLeftIcon color={tw`text-light-light`.color} size={5} />
        <Text style={tw.style(`ml-0.5xs text-3xl text-white font-primaryMedium font-spacing-md`, {
          textShadowColor: tw`text-light-light`.color,
          textShadowRadius: 1
        })}>Offers</Text>
      </Pressable>

      <View style={tw`w-full max-w-192 px-base`}>
        <View style={tw`w-full right-0.5xs mb-1.5xl`}>
          <View style={tw.style(`absolute justify-end top-1.5base left-xs w-full h-full rounded-sm bg-white bg-opacity-60 shadow overflow-hidden`)}>
            {isDesktopWeb ? null : <BlurView style={tw.style(`absolute w-full h-full rounded-sm`)}
              opacity={0.75}
              blurAmount={24}
              blurType={'light'} />}

            <LinearGradient colors={[currentGradient[0], pSBC(-0.5, currentGradient[1])].reverse()}
              style={tw.style(`absolute w-full h-full rounded-sm`, {
                opacity: 0.64,
              })}
              start={{x: 0.5, y: 0.1}}
              end={{x: 0.5, y: 0.9}}
              locations={[0, 1]} />

            <View style={tw`flex-row justify-between absolute w-full`}>
              <Text style={tw.style(`pb-0.25xs pl-xs text-1.5sm text-white font-primaryBold font-spacing-sm`, {
                textShadowColor: tw`text-light-light`.color,
                textShadowRadius: 1
              })}>Ends {formatUtcDate(new Date(offer.end_date))}</Text>
              <Text style={tw.style(`pb-0.25xs pr-base text-1.5sm text-white font-primaryBold font-spacing-sm`, {
                textShadowColor: tw`text-light-light`.color,
                textShadowRadius: 1
              })}>{redemmedText}</Text>
            </View>
          </View>

          <View style={tw`w-full items-center pt-xs pb-base px-base rounded-sm bg-white shadow`}>
            <View style={tw`w-full p-sm`}>
              {<ImageWithProgress style={tw`w-full h-[${imageHeight}px] mb-xs`}
                onLayout={event => setOfferImageContainerWidth(event.nativeEvent.layout.width)}
                source={{uri: scaledOfferImage}}
                indicator={ProgressIndicator}
                indicatorProps={{color: vibrantColors[0], fill: vibrantColors[1]}}
                resizeMethod='scale'
                resizeMode='contain'
                progressiveRenderingEnabled={true}
                fadeDuration={210} />}
            </View>

            <View style={tw.style(`mb-sm pb-sm border-b border-[${pSBC(-0.35, currentGradient[1])}]`)}>
              <Text style={tw.style(`text-6xl text-center text-dark-dark font-primaryMedium font-spacing-md`, {
                textShadowColor: `rgba(228, 231, 234, 0.48)`,
                textShadowRadius: 1
              })}>{offer.name}</Text>
            </View>

            <Text style={tw.style(`text-center text-dark-dark font-primary font-spacing-sm`, {
              textShadowColor: `rgba(228, 231, 234, 0.48)`,
              textShadowRadius: 1
            })}>{offer.details}</Text>
          </View>
        </View>

        <View style={tw`items-center w-full mb-base`}>
          {!auth ? <LoginCallout /> : auth.user.verified ? <RedeemForm offer={offer} limitReached={limitReached} /> : <VerifyCallout />}
        </View>

        <View style={tw.style(``)}>
          <Text style={tw.style(`text-center text-white font-primary font-spacing-sm`)}>{offer.conditions}</Text>
          <Text style={tw.style(`text-center text-white font-primary font-spacing-sm`)}>Offer ID:{offer.id}</Text>
        </View>
      </View>
    </ScrollView> : <LoadingSpinner />}
  </LinearGradient>
})

export default OfferPage;
