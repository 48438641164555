import {View, Text, Pressable} from "react-native";
import {useContext, useState, useEffect, useLayoutEffect} from "react";
import {useSpringValue} from "./utilities/wrapSpring";
import {pSBC} from "js-utilities";

import Context from "./context.jsx";

import AnimatedBlurPanel from './ui/animatedBlurPanel.jsx';


export default function StatusMessage() {
  const {tw, statusMessage, displayStatusMessage} = useContext(Context);

  const [animationCompleted, setAnimationCompleted] = useState(true);

  const blurViewOpacity = useSpringValue(0, {duration: 333, onRest: () => {
    setAnimationCompleted(true);
  }});
  const linearGradientOpacity = useSpringValue(0.24, {duration: 333});
  const linearGradientLocations = useSpringValue([0, 0, 1, 1], {duration: 200});

  useLayoutEffect(() => {
    setAnimationCompleted(false);
    blurViewOpacity.start(statusMessage.visible ? 1 : 0);
    linearGradientOpacity.start(statusMessage.visible ? 0.67 : 0);
    linearGradientLocations.start(statusMessage.visible ? [0, 0.33, 0.67, 1] : [0, 0, 1, 1]);
  }, [statusMessage.visible]);

  const statusColorMap = {
    success: tw`text-green-accent`.color,
    error: tw`text-ruby`.color,
    info: tw`text-blue-accent`.color};

  const color = statusColorMap[statusMessage?.type] || tw`text-blue-accent`.color;

  return <View style={tw.style(`items-center absolute bottom-base z-30 w-full min-h-16 px-base ${statusMessage.visible ? '' : animationCompleted ? 'hidden' : ''}`, {})}
      pointerEvents={statusMessage.visible ? 'box-none' : 'none'}>
      <AnimatedBlurPanel style={tw.style(`w-full max-w-128 h-full`)}
        gradient={[pSBC(-0.33, color), pSBC(-0, color), pSBC(-0.33, color), pSBC(-0.67, color)]}
        blurViewOpacity={blurViewOpacity}
        linearGradientOpacity={linearGradientOpacity}
        gradientLocations={linearGradientLocations}
        roundedFull
        opacityOnChildren>

        <View style={tw`justify-center items-center w-full h-full z-10 p-xs`}>
          <Text style={tw`text-center text-sm text-white font-primaryBold capitalize `}>{statusMessage?.message}</Text>
          <Text style={tw`text-center text-sm text-white font-primary font-spacing-sm`}>{statusMessage?.details}</Text>
        </View>

        <Pressable style={tw.style(`absolute w-full h-full z-40`)} onPress={() => displayStatusMessage()}/>
      </AnimatedBlurPanel>
    </View>
}
