const pathToPageTitle = {
	'': 'Offers',
	'age': 'Age',
	'location': 'Location',
	'offer': 'Individual Offer',
	'offer-detail': 'Individual Offer (Redirect)',
	'account': 'Account',
	'verify': 'Verify',
	'balance': 'Balance',
	'login': 'Login',
	'signup': 'Sign Up',
	'reset-password': 'Request Password Reset',
	'reset-password-2': 'Set New Password'
}

export default pathToPageTitle;