import {useContext} from "react";
import {View} from "react-native";
import {Svg, Path} from 'react-native-svg';

import Context from "../context.jsx";


export default function ChevronUpIcon({size, color, style={}}) {
  const {tw} = useContext(Context);

  return <View style={tw.style(`w-${size} h-${size}`, style)}>
    <Svg xmlns="http://www.w3.org/2000/svg"
      style={tw`w-full h-full`}
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={3.25}
      stroke={color}>
      <Path d="M4.5 15.75l7.5-7.5 7.5 7.5"
        transform="rotate(180, 10, 10) translate(-3, -5)"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </Svg>
  </View>;
}
