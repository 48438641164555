import {useContext} from "react";
import {Svg, Circle, Path} from 'react-native-svg';

import Context from "../context.jsx";


export default function EndingSoonIcon({size, color, backgroundColor, style}) {
  const {tw} = useContext(Context);

  return <Svg xmlns="http://www.w3.org/2000/svg"
    style={tw.style(style ? style : null, {height: size, width: size})}
    fill={backgroundColor}
    viewBox="0 0 48 48"
    strokeWidth={1.5}
    stroke={backgroundColor}>

    <Circle fill={color}
      cx='50%'
      cy='50%'
      r='47%' />

    {/* <Path transform="matrix(1 0 0 -1 0 963)"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M512 934.4c268.631 0 486.4-217.769 486.4-486.4s-217.769-486.4-486.4-486.4c-268.631 0-486.4 217.769-486.4 486.4s217.769 486.4 486.4 486.4zM563.2 217.6h-102.4v-102.4h102.4v102.4zM563.2 755.2h-102.4v-486.4h102.4v486.4z" /> */}

    <Path transform="translate(-2, -2) scale(1.086)" d="M 24 4 C 12.972066 4 4 12.972074 4 24 C 4 35.027926 12.972066 44 24 44 C 35.027934 44 44 35.027926 44 24 C 44 12.972074 35.027934 4 24 4 z M 24 7 C 33.406615 7 41 14.593391 41 24 C 41 33.406609 33.406615 41 24 41 C 14.593385 41 7 33.406609 7 24 C 7 14.593391 14.593385 7 24 7 z M 23.476562 11.978516 A 1.50015 1.50015 0 0 0 22 13.5 L 22 25.5 A 1.50015 1.50015 0 0 0 23.5 27 L 31.5 27 A 1.50015 1.50015 0 1 0 31.5 24 L 25 24 L 25 13.5 A 1.50015 1.50015 0 0 0 23.476562 11.978516 z"/>
  </Svg>;
}
