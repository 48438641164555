import {useContext, useState, useEffect} from 'react';
import {View, Text, Pressable, Image} from 'react-native';
import {ajaxRequest} from 'js-utilities';

import Paypal from '../assets/images/payout-option_paypal.png'
import Venmo from '../assets/images/payout-option_venmo.png'
import Visa from '../assets/images/payout-option_visa.png'

import Context from './context.jsx';

import Page from './ui/page.jsx';
import ConsumerBalancesList from './consumerBalancesList.jsx';
import MediaUploadsList from './mediaUploadsList.jsx';
import Button from './ui/button.jsx';
import LoadingSpinner from './ui/loadingSpinner';


export default function Balance() {
  const {tw,
    consumerBalances,
    consumerBalancesReady,
    getConsumerBalances,
    config,
    auth,
    selectedState,
    mediaUploads,
    mediaUploadsReady,
    getMediaUploads,
    analyticsEvent,
    navigate,
    displayStatusMessage} = useContext(Context)

  const [selectedPayoutMethod, setSelectedPayoutMethod] = useState(false)
  const [pendingConsumerBalance, setPendingConsumerBalance] = useState(false)
  const [pendingMediaUploads, setPendingMediaUploads] = useState([])
  const [rejectedMediaUploads, setRejectedMediaUploads] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(getConsumerBalances, [])

  useEffect(function() {
    if (![consumerBalancesReady, consumerBalances.length]
      .every(Boolean)) return;

    const tempPendingConsumerBalance = consumerBalances
      .find(function(consumerBalance) {
        return consumerBalance.status === 'pending'})
        
    if (tempPendingConsumerBalance)
      setPendingConsumerBalance(tempPendingConsumerBalance)
  }, [consumerBalances, consumerBalancesReady])

  useEffect(function() {
    if (![mediaUploadsReady, mediaUploads.length]
      .every(Boolean)) return;

    setPendingMediaUploads(mediaUploads
      .filter(function(mediaUpload) {
        return ['submitted', 'in_review']
          .includes(mediaUpload.status)}))

    setRejectedMediaUploads(mediaUploads
      .filter(function(mediaUpload) {
        return !['submitted', 'in_review']
          .includes(mediaUpload.status)}))
  }, [mediaUploads, mediaUploadsReady])

  function handlePayout() {
    setLoading(true)
    if (pendingConsumerBalance) ajaxRequest('POST',
      `${config.apiEndpoint}/consumer_balances`, {
        headers: auth.authHeaders,
        params: {payment_method: selectedPayoutMethod}
      }).then(function() {
        const value = pendingConsumerBalance
          ?.balance_cents / 100
        const redemptionsCount = pendingConsumerBalance
          ?.redemptions?.length
        setLoading(false)
        getMediaUploads()
        getConsumerBalances()
        displayStatusMessage({type: 'success',
          message: 'Money is on the way',
          details: 'You should receive your balance in just a few moments'})
        analyticsEvent('balancePayout', {
          value, redemptionsCount,
          payoutMethod: selectedPayoutMethod,
          state: selectedState?.name})
        navigate('/')
      }).catch(function() {
        setLoading(false)
        getMediaUploads()
        getConsumerBalances()
        displayStatusMessage({type: 'error',
          message: 'Something Went Wrong',
          details: 'Please contact support for assistance'})
      })
  }

  function PayoutMethod({payoutMethod, image, imageAlt, details}) {
    return <Pressable style={tw`items-center bg-white w-full max-w-90 m-xs border border-light rounded-md shadow ${selectedPayoutMethod === payoutMethod ? 'border-primary-dark' : 'border-light'}`}
      onPress={() => setSelectedPayoutMethod(payoutMethod)}>
      <View style={tw`items-center justify-center h-32 p-xs`}>
        <Image style={tw`w-40 h-15`} 
          source={image} 
          alt={imageAlt}
          resizeMethod='resize'
          resizeMode='contain'/>

        <View style={tw`grow justify-center mx-base`}>
          <Text style={tw`text-center text-dark-dark font-secondary font-spacing-sm`}>
            {details}
          </Text>
        </View>
      </View>
  
      <View style={tw`items-center w-full border-t border-light-light rounded-b-md p-xs ${selectedPayoutMethod === payoutMethod ? `bg-primary-dark border-primary-dark` : 'border-light'}`}>
        <Text style={tw`uppercase font-primaryMedium ${selectedPayoutMethod === payoutMethod ? 'text-white' : ''}`}>Select</Text>
      </View>
    </Pressable>}

  return <Page header={'Balance'} maxWidth='wide'>
    <View style={tw`w-full`}>
      <View style={tw`items-center p-base pb-lg border-b border-light`}>
        <Text style={tw`text-8.5xl text-accent-dark2 font-primaryBold`}>
          {pendingConsumerBalance ? `$${(pendingConsumerBalance.balance_cents / 100).toFixed(2)}` : '$0.00'}
        </Text>
      </View>

      {pendingConsumerBalance ? <View style={tw`items-center py-base`}>
        <Text style={tw`mb-base text-5xl text-dark-dark font-primaryMedium leading-normal`}>Select Payout Method</Text>

        <View style={tw`flex-row flex-wrap justify-center mb-base`}>
          <PayoutMethod payoutMethod='paypal'
            image={Paypal}
            imageAlt='Paypal Logo'
            details={`Recieve your rebate to your paypal account at ${auth.user.email}`} />

          {auth.user.phone_number ? <PayoutMethod payoutMethod='venmo'
            image={Venmo}
            imageAlt='Venmo Logo'
            details={`Recieve your rebate to your venmo account at ${auth.user.phone_number}`} /> : null}

          <PayoutMethod payoutMethod='visa'
            image={Visa}
            imageAlt='Visa Logo'
            details={`Recieve a vanilla e-Reward Visa Virtual Account, 6-Month Sweep at ${auth.user.email}`} />
        </View>

        {loading ? <LoadingSpinner /> : <Button onPress={handlePayout}
          color={tw`text-primary`.color}
          text='Cash Out'
          disabled={!selectedPayoutMethod} />}
      </View>: <></>}

      <View style={tw`justify-center items-center p-xs w-full`}>
        {pendingMediaUploads.length > 0 ? <MediaUploadsList type='pending'
          title='Pending Submissions'
          mediaUploads={pendingMediaUploads}
          initiallyExpandedIndexes={[0]} /> : <></>}

        {pendingConsumerBalance.length > 0 ? <ConsumerBalancesList title='Current Balance'
          consumerBalances={[pendingConsumerBalance]}
          initiallyExpanded /> : <></>}

        {consumerBalances.length > 0 ? <ConsumerBalancesList title='Account History'
          consumerBalances={pendingConsumerBalance
            ? consumerBalances.slice(1)
            : consumerBalances} /> : <></>}

        {rejectedMediaUploads.length > 0 ? <MediaUploadsList type='rejected'
          title='Rejected Submissions'
          mediaUploads={rejectedMediaUploads} /> : <></>}
      </View>

      <View>
        <Text style={tw`text-xs text-dark-dark text-left py-2`}>
          Please note: All rebates are processed by BYBE, Inc., an independent third party with no direct or indirect interest in any alcohol beverage licensee. 
          Rebate is fully funded by non-retail licensee. Vanilla eReward Visa Virtual Account may be used online or over the phone in the U.S. and District of Columbia everywhere Visa debit is accepted. 
          The virtual account may not be used at any merchant, including Internet and mail or telephone order merchants, outside of the U.S. or the District of Columbia. Terms and conditions apply. 
          See Virtual Accountholder Agreement for details. The Vanilla eReward Visa Virtual Account is issued by The Bancorp Bank pursuant to a license from Visa U.S.A. Inc. 
          The Bancorp Bank; Member FDIC. Virtual Account is distributed and serviced by InComm Financial Services, Inc., which is licensed as a Money Transmitter by the New York State Department of Financial Services.
        </Text>
      </View>
    </View>
  </Page>
}
