import {useContext} from "react";
import {Svg, Path} from 'react-native-svg';

import Context from "../context.jsx";


export default function MenuIcon({size, color}) {
  const {tw} = useContext(Context);

  return <Svg xmlns="http://www.w3.org/2000/svg"
    style={tw`w-${size} h-${size}`}
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke={color}>
    <Path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
  </Svg>;
}
