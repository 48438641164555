import {useState, useEffect} from 'react';

import {ajaxRequest} from "js-utilities";


export default function useFaq(config, toForeground, errors, setErrors) {
  const [faq, setFaq] = useState([]);
  const [faqLoading, setFaqLoading] = useState(false);
  const [faqReady, setFaqReady] = useState(false);

  useEffect(getFaq, [toForeground])

  function getFaq() {
    setFaqLoading(true);
    ajaxRequest('GET', `${config.apiEndpoint}/faqs`, {
      json: true,
    }).then(response => {
      setFaq(response.body.faqs);
      setFaqLoading(false);
      setFaqReady(true);
    }).catch(error => {
      setErrors([...errors, error.body]);
      setFaqLoading(false);
      setFaqReady(true);
    });
  }

  return {faq,
    faqLoading,
    faqReady,
    getFaq};
}
