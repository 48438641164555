import {View, Text, Image, Pressable, PixelRatio} from 'react-native';
import {memo, useMemo, useState, useRef, useContext} from 'react';
import {decode as atob, encode as btoa} from 'base-64';
import {Grayscale} from '../assets/functions/objects';

import {formatUtcDate, truncateStringPreservingWords, formatMoneyCondensed, pSBC} from 'js-utilities';
import offerVibrantColors from '../assets/functions/offerVibrantColors.js';
import Context from './context.jsx';
import useMmkvStorage from './hooks/useMmkvStorage.jsx';

import ChevronRightIcon from './icons/chevronRightIcon.jsx';
import {LinearGradient} from 'expo-linear-gradient';


function FeaturedOfferListItem({offer, tw, navigate, consumerBalances, consumerBalancesReady, log}) {
  const timesRedeemed = useMemo(() => consumerBalancesReady
    && consumerBalances.reduce(function(acc, balance) {
      return acc + balance.redemptions.filter(function(redemption) {
        return redemption.offer.id === offer.id
      }).length
    }, 0),
  [consumerBalances, consumerBalancesReady])

  const [imageContainerWidth100, setImageContainerWidth100] = useState(100)
  const limitReached = offer ? timesRedeemed >= offer.per_consumer_limit : false
  const redemmedText =
    timesRedeemed > 0
      ? `Redemmed ${timesRedeemed} / ${offer.per_consumer_limit}`
      : `Limit ${offer.per_consumer_limit}`
  const thumbnailUrl = useMemo(
    () => offerThumbImageUrl({width: imageContainerWidth100, filename: offer.image_url.split('/').pop()}),
    [imageContainerWidth100, offer]
  )
  const imageAspectStorage = useMmkvStorage()
  const setOfferAspectRatio = (width, height) => {
    if (width > 0 && height > 0) imageAspectStorage.set(`image-aspect-${offer.id}`, width / height);
  };

  return <View style={tw.style(`h-72 w-52 max-w-52 p-0.5xs`)}>
    <Pressable onPress={() => {
        navigate(`/offer/${offer.id}`)
      }}>
      <View style={tw`flex-col w-full h-full web:border web:border-light-light rounded bg-white shadow`}
        onLayout={event => setImageContainerWidth100(Math.ceil(event.nativeEvent.layout.width/100)*100)}>
        <Grayscale amount={limitReached ? 1 : 0}>
          <Image style={tw`h-32 w-32 mx-auto my-0.5xs`}
            source={{uri: thumbnailUrl}}
            resizeMethod='scale' resizeMode='contain'
            onLoad={() => setTimeout(() => Image.getSize(thumbnailUrl, setOfferAspectRatio), 0)}
            />
        </Grayscale>

        <View style={tw`mb-0.75xs flex-row`}>
          <Text style={tw`py-0.5 px-1.75 rounded-r-md ${limitReached ? 'bg-light-dark' : 'bg-secondary-original'} text-base text-white font-semibold font-spacing shadow`}
            numberOfLines={1}>
            {`Buy ${offer.required_products[0].quantity}, Get ${formatMoneyCondensed(offer.discount_cents)} ${formatMoneyCondensed(offer.discount_cents).length < 4 ? 'Back' : ''}`}
          </Text>
        </View>

        <View style={tw`grow max-h-20 mb-1.5xs pl-1.25`}>
          <Text style={tw`mb-0.5 text-sm text-dark-accent font-spacing-sm font-primary`}
            numberOfLines={1}
            ellipsizeMode='clip'>
            {offer.name}
          </Text>
          <Text style={tw`text-xs text-light font-secondary font-light`}
            numberOfLines={3}
            ellipsizeMode='clip'>
            {truncateStringPreservingWords(offer.details, 70) + '...'}
          </Text>
        </View>

        <View style={tw`flex-row pl-1.25 mb-0.75xs`}>
          <Text style={tw`mr-1.25 text-xs text-dark font-secondary`}>Ends {formatUtcDate(new Date(offer.end_date))}</Text>
          <Text style={tw`mr-1.25 text-center text-xs text-dark font-secondary`}>{redemmedText}</Text>
        </View>

        {offerEndingSoonDays(offer) ? <LinearGradient style={tw`absolute top-0 left-0 py-0.5 px-1.75 rounded-br-md rounded-tl shadow`}
          colors={limitReached ? [tw`text-light-dark`.color,pSBC(-0.5, tw`text-light-dark`.color)] : offerVibrantColors(tw, offer).map(color => pSBC(-0.33, color))}
          start={{x: 0.1, y: 0.9}}
          end={{x: 0.9, y: 0.1}}>
          <Text style={tw`text-2xs text-center text-white font-primaryMedium`}>{offerEndingSoonDays(offer)}</Text>
        </LinearGradient> : null}
      </View>
    </Pressable>
  </View>;
};

function OfferListItem({offer, offerHeight, tw, navigate, consumerBalances, consumerBalancesReady, numColumns, log}) {
  const timesRedeemed = useMemo(() => consumerBalancesReady
    && consumerBalances.reduce(function(acc, balance) {
      return acc + balance.redemptions.filter(function(redemption) {
        return redemption.offer.id === offer.id
      }).length
    }, 0),
  [consumerBalances, consumerBalancesReady]);

  const [imageContainerWidth100, setImageContainerWidth100] = useState(100)
  const offerThumbImage = useRef()
  const limitReached = offer ? timesRedeemed >= offer.per_consumer_limit : false
  const redemmedText =
    timesRedeemed > 0
      ? `Redemmed ${timesRedeemed} / ${offer.per_consumer_limit}`
      : `Limit ${offer.per_consumer_limit}`
  const thumbnailUrl = useMemo(
    () => offerThumbImageUrl({width: imageContainerWidth100, filename: offer.image_url.split('/').pop()}),
    [imageContainerWidth100, offer]
  )
  const imageAspectStorage = useMmkvStorage()
  const setOfferAspectRatio = (width, height) => {
    if (width > 0 && height > 0) imageAspectStorage.set(`image-aspect-${offer.id}`, width / height);
  };

  return <View style={tw.style(`p-0.5xs md:w-1/2 lg:w-1/3 xl:w-1/4`)}>
    <Pressable onPress={() => {
        navigate(`/offer/${offer.id}`);
      }}>
      <View style={tw`flex-row ${numColumns > 1 ? 'h-32' : 'h-34'} web:border web:border-light-light rounded bg-white shadow`}>
        <View style={tw`justify-center items-center w-2/6`}
          onLayout={event => setImageContainerWidth100(Math.ceil(event.nativeEvent.layout.width/100)*100)}>
          <Grayscale amount={limitReached ? 1 : 0}>
            <Image style={tw`h-24 w-24 my-1.25`} ref={offerThumbImage}
              source={{uri: thumbnailUrl}}
              resizeMethod='scale' resizeMode='contain'
              onLoad={() => setTimeout(() => Image.getSize(thumbnailUrl, setOfferAspectRatio), 0)}
              />
          </Grayscale>
        </View>

        <View style={tw`flex-col flex-1`}>
          <View style={tw`flex-row mb-0.5xs`}>
            <Text style={tw`py-0.5 px-1.75 rounded-b-md ${limitReached ? 'bg-light-dark' : 'bg-green-accent'} text-base text-white font-primary font-semibold font-spacing shadow`}>
              {`Buy ${offer.required_products[0].quantity}, Get ${formatMoneyCondensed(offer.discount_cents)} ${formatMoneyCondensed(offer.discount_cents).length < 4 ? 'Back' : ''}`}
            </Text>
          </View>

          <View style={tw`grow mb-xs px-1`}>
            <Text style={tw`mb-0.5 text-sm text-dark-accent font-spacing-sm font-primaryMedium`}
              numberOfLines={2}
              ellipsizeMode='clip'>
              {offer.name}
            </Text>
            <Text style={tw`text-1.5xs text-light font-secondary font-light`} numberOfLines={2} ellipsizeMode='clip'>{truncateStringPreservingWords(offer.details, 70) + '...'}</Text>
          </View>

          <View style={tw`flex-row px-1 mb-0.75xs`}>
            <Text style={tw`mr-1.25 text-center text-xs text-dark font-secondary`}>Ends {formatUtcDate(new Date(offer.end_date))}</Text>
            <Text style={tw`mr-1.25 text-center text-xs text-dark font-secondary`}>{redemmedText}</Text>
          </View>
        </View>

        <View style={tw`justify-center items-center w-1/6`}>
          <ChevronRightIcon size={14}
            color={tw`text-primary-dark`.color} />
        </View>

        {offerEndingSoonDays(offer) ? <LinearGradient style={tw`absolute bottom-0 left-0 py-0.5 px-1.75 rounded-bl rounded-tr-md shadow`}
          colors={limitReached ? [tw`text-light-dark`.color,pSBC(-0.5, tw`text-light-dark`.color)] : offerVibrantColors(tw, offer).map(color => pSBC(-0.33, color))} start={{x: 0.1, y: 0.9}}
          end={{x: 0.9, y: 0.1}}>
          <Text style={tw`text-3xs text-center text-white font-primaryMedium`}>{offerEndingSoonDays(offer)}</Text>
        </LinearGradient> : null}
      </View>
    </Pressable>
  </View>;
};

function offerEndingSoonDays(offer) {
  const endDate = new Date(offer.end_date);
  const utcEndDate = Date.UTC(endDate.getUTCFullYear(), endDate.getUTCMonth()+1, endDate.getUTCDate());

  const todaysDate = new Date();
  const todaysUtcDate = Date.UTC(todaysDate.getUTCFullYear(), todaysDate.getUTCMonth()+1, todaysDate.getUTCDate());

  const daysLeft = Math.trunc((utcEndDate - todaysUtcDate) / 86400000);

  if(daysLeft === 0) return `Last Day`;
  if(daysLeft === 1) return `${daysLeft} Day Left`;
  return daysLeft <= 3 ? `${daysLeft} Days Left` : false;
}

function offerThumbImageUrl({width, filename}) {
  let target_size = Math.min(
    // 100 * Math.ceil(PixelRatio.getPixelSizeForLayoutSize(width > height ? width : height)/100),
    100 * Math.ceil(PixelRatio.getPixelSizeForLayoutSize(width) / 100),
    500 // maximum size to request, no matter the PixelRatio
  );
  let request_path = btoa(JSON.stringify({
    key: `offers/${filename}`,
    edits: {
      resize: {
        width: target_size, height: target_size, fit: 'inside'
      },
      trim: {
        threshold: 10, // number for color difference threshold
        // background: "#ffffff",
      },
    }
  }));
  return `https://images.bybe.io/${request_path}`;
}

FeaturedOfferListItem = memo(FeaturedOfferListItem);
OfferListItem = memo(OfferListItem);

export {FeaturedOfferListItem, OfferListItem};
