import {View, Text, Pressable, Animated, Linking} from "react-native";
import {useContext, useState, useEffect, useRef} from "react";
import {animated, useSpring} from "./utilities/wrapSpring";
import {formatMoneyCondensed} from "js-utilities";


import Context from "./context.jsx";
import {BlurView} from "../assets/functions/objects";
import {LinearGradient} from 'expo-linear-gradient';


export default function Menu() {
  const {tw, inset, auth, logout, navigate, selectedState, headerHeight, contentHeight, swipableViewProps, menuOpen, setMenuOpen, currentGradient, setModalVisible, isDesktopWeb, isIos, consumerBalances, consumerBalancesReady} = useContext(Context);

  const slideAnimation = useRef(new Animated.Value(0)).current;
  const opacityAnimation = useRef(new Animated.Value(0)).current;

  // const [layout, setLayout] = useState();
  const [layoutWidth, setLayoutWidth] = useState();
  const [pendingConsumerBalance, setPendingConsumerBalance] = useState();

  useEffect(() => {
    if (consumerBalancesReady && consumerBalances.length) {
      const tempPendingConsumerBalance = consumerBalances.find(consumerBalance => consumerBalance.status === 'pending');
      if (tempPendingConsumerBalance) setPendingConsumerBalance(tempPendingConsumerBalance);}
  }, [consumerBalances, consumerBalancesReady]);

  // const AnimatedView = animated(View);
  // const AnimatedBlurView = animated(BlurView);

  // const [animatedViewSprings, animatedViewApi] = useSpring(() => ({
  //   from: {opacity: 0},
  //   config: {duration: 200}
  // }));

  // const [animatedBlurViewSprings, animatedBlurViewApi] = useSpring(() => ({
  //   from: {opacity: 0},
  //   config: {duration: 200}
  // }));

  const AnimatedBlurView = Animated.createAnimatedComponent(BlurView);

  useEffect(() => {
    if (menuOpen) slideIn();
    else slideOut();
  }, [menuOpen]);

  useEffect(() => {
    if (menuOpen) increaseOpacity();
    else decreaseOpacity();
  }, [menuOpen]);

  const slideIn = () => {
    Animated.timing(slideAnimation, {
      toValue: 1,
      duration: 200,
      useNativeDriver: true
    }).start();
  };

  const slideOut = () => {
    Animated.timing(slideAnimation, {
      toValue: 0,
      duration: 333,
      useNativeDriver: true
    }).start();
  };

  const increaseOpacity = () => {
    Animated.timing(opacityAnimation, {
      toValue: 1,
      duration: 200,
      useNativeDriver: true
    }).start();
  };

  const decreaseOpacity = () => {
    Animated.timing(opacityAnimation, {
      toValue: 0,
      duration: 333,
      useNativeDriver: true
    }).start();
  };

  const transform = layoutWidth ? [
    {translateX: (layoutWidth / 2)},
    {scaleX: slideAnimation},
    {translateX: -1 * (layoutWidth / 2)}
  ] : [
    {scaleX: slideAnimation}
  ];

  return <View pointerEvents={menuOpen ? 'auto' : 'none'} {...(menuOpen ? swipableViewProps : {})}
    style={tw`absolute w-full h-[${contentHeight}px] top-[${headerHeight + (isIos ? inset.top : 0)}px] z-50 overflow-hidden`}>
    <AnimatedBlurView style={tw.style(`absolute w-full h-full`, {opacity: opacityAnimation})}
      blurAmount={4}
      blurType='dark' />
    <Pressable style={tw.style(`absolute w-full h-full`)} onPress={() => setMenuOpen(false)} />

    <Animated.View style={tw.style(`absolute right-0 w-7/12 h-full max-w-96 overflow-hidden`, {transform})}
      needsOffscreenAlphaCompositing={true}>
      <AnimatedBlurView style={tw.style(`absolute w-full h-full`, {opacity: opacityAnimation})}
        onLayout={event => {if (menuOpen) setLayoutWidth(event.nativeEvent.layout.width)}}
        blurAmount={24}
        blurType={'dark'}
        needsOffscreenAlphaCompositing={true} />

      {/* Extra View to fix blurview bug */}
      <View style={tw.style(`w-full h-full opacity-99.99`)}>
        <LinearGradient colors={currentGradient}
          style={tw.style(`absolute w-full h-full`, {
            opacity: 0.4
          })}
          start={{x: 0.10, y: 0.9}}
          end={{x: 0.9, y: 0.10}}
          locations={[0, 1]} />

        {selectedState && !isDesktopWeb ? <Pressable onPress={() => navigate('/location')} style={tw`mx-xl py-1.5base border-b-2 border-green-accent`}>
          <Text style={tw`mb-0.5xs text-center text-white font-primary`}>Offers in</Text>
          <Text style={tw`text-3xl text-center text-white font-primaryMedium`}>{selectedState.name}</Text>
        </Pressable> : null}

        <View style={tw`flex-col flex-1 justify-between py-1.5base mx-base`}>
          {auth ? <View>
            <Pressable onPress={() => navigate('/account')} style={tw`mb-base`}>
              <Text style={tw`text-center text-white font-primary`}>Account</Text>
            </Pressable>

            <Pressable onPress={() => navigate('/balance')} style={tw`mb-base`}>
              <Text style={tw`text-center text-white font-primary`}>Balance</Text>
            </Pressable>
          </View> : <View>
            <Pressable onPress={() => navigate('/login')} style={tw`mb-base`}>
              <Text style={tw`text-center text-white font-primary`}>Login</Text>
            </Pressable>

            <Pressable onPress={() => navigate('/signup')} style={tw``}>
              <Text style={tw`text-center text-white font-primary`}>Sign Up</Text>
            </Pressable>
          </View>}
          <View>
            <Pressable onPress={() => setModalVisible(true)} style={tw`mb-1.5base`}>
              <Text style={tw`text-center text-white font-primary`}>How To</Text>
            </Pressable>

            <Pressable onPress={() => navigate('/faq')} style={tw`mb-1.5base`}>
              <Text style={tw`text-center text-white font-primary`}>FAQ</Text>
            </Pressable>

            <Pressable onPress={() => Linking.openURL(`mailto:support@bybeapp.com`)} style={tw`mb-1.5base`}>
              <Text style={tw`text-center text-white font-primary`}>Help</Text>
            </Pressable>

            <Pressable onPress={() => Linking.openURL(`https://bybe.com/privacy-policy.html`)} style={tw`mb-1.5base`}>
              <Text style={tw`text-center text-white font-primary`}>Privacy Policy</Text>
            </Pressable>

            <Pressable onPress={() => Linking.openURL(`https://bybe.com/terms-and-conditions.html`)} style={tw`mb-1.5base`}>
              <Text style={tw`text-center text-white font-primary`}>Terms and Conditions</Text>
            </Pressable>

            {auth ? <Pressable onPress={logout} style={tw`mb-1.5base`}>
              <Text style={tw`text-center text-white font-primary`}>Log Out</Text>
            </Pressable> : null}
          </View>
        </View>
      </View>
    </Animated.View>
  </View>;
}
