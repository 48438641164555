import {useContext, useState, useEffect} from "react";
import {View, Text, TextInput, Pressable, Switch} from "react-native";

import Context from "./context.jsx";

import Page from "./ui/page.jsx";
import Button from './ui/button.jsx';
import LoadingSpinner from "./ui/loadingSpinner.jsx";


export default function Account() {
  const {tw, auth, updateAccount, navigate, currentGradient, displayStatusMessage} = useContext(Context);
  const [verified,setVerified] = useState(false);
  const [disabled,setDisabled] = useState(false);
  const [birthDay,setBirthday] = useState('');
  const [firstName,setFirstName] = useState('');
  const [lastName,setLastName] = useState('');
  const [email,setEmail] = useState('');
  const [phoneNum,setPhoneNum] = useState('');
  const [password,setPassword] = useState('');
  const [newPassword,setnewPassword] = useState('');
  const [rememberMe,setRememberMe] = useState(true);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (auth) {
      setBirthday(auth.user.date_of_birth);
      setFirstName(auth.user.first_name);
      setLastName(auth.user.last_name);
      setEmail(auth.user.email);
      setPhoneNum(auth.user.phone_number || '');
      setVerified(auth.user.verified);
      setDisabled(auth.user.disabled);}
  },[auth]);

  function handleSubmit() {
    setLoading(true);
    if (password.length < 8){
      setLoading(false);
      return displayStatusMessage({type: 'error', message: 'Invalid Password', details: 'Password must be at least 8 characters'});
    }

    if(newPassword){
      if (password.length == 0){
        setLoading(false);
        return displayStatusMessage({type: 'error', message: 'Invalid Password', details: 'Please Enter Your Current Password'});}

      if (password == newPassword){
        setLoading(false);
        return displayStatusMessage({type: 'error', message: 'Invalid Password', details: 'Passwords can not match'});}
        
      if (newPassword < 8){
        setLoading(false);
        return displayStatusMessage({type: 'error', message: 'Invalid Password', details: 'Password must be at least 8 characters'});}
    }
    updateAccount(birthDay, email, firstName, lastName, password, newPassword, rememberMe).then(({newAuth, error})=>{
      if(error) return setLoading(false);
      setLoading(false);
      displayStatusMessage({type: 'success', message: 'Account Updated', details: 'Your account has been updated successfully'});
      navigate('/')
    });}

  return <Page header={<View style={tw`flex-row items-center`}>
    <Text style={tw.style(`ml-0.5xs text-8xl text-white font-primaryMedium font-spacing-lg`, {
      textShadowColor: tw`text-light-light`.color,
      textShadowRadius: 1
    })}>Account</Text>

    { verified ? <View style={tw`items-center justify-center items-center h-8 ml-xs px-xs border-2 border-white rounded bg-green-accent`}>
      <Text style={tw`text-center text-white font-primaryMedium`}>Verified</Text>
    </View> : <Pressable style={tw`items-center justify-center items-center h-8 ml-xs px-xs border-2 border-white rounded bg-ruby`} onPress={() => navigate('/verify')}>
      <Text style={tw`text-center text-white font-primaryMedium`}>Verify</Text>
    </Pressable> }
  </View>}>

    <View style={tw`w-full`}>
      {disabled ? <Text style={tw`mb-xs text-center text-sm text-ruby font-primary font-spacing-sm`}>
        You're Account Has Been Disabled, Please Contact Support at Support@bybeapp.com If You Believe This Is a Mistake
      </Text> : null}

      <Text style={tw`mb-xs text-lg text-dark-dark font-primaryMedium font-spacing`}>First Name</Text>
      <TextInput style={tw`mb-xs p-xs rounded-sm border border-dark text-lg font-primary font-spacing outline-none`}
        value={firstName}
        onChangeText={firstName => setFirstName(firstName)} />

      <Text style={tw`mb-xs text-lg text-dark-dark font-primaryMedium font-spacing`}>Last Name</Text>
      <TextInput style={tw`mb-xs p-xs rounded-sm border border-dark text-lg font-primary font-spacing outline-none`}
        value={lastName}
        onChangeText={lastName => setLastName(lastName)} />

      <Text style={tw`mb-xs text-lg text-dark-dark font-primaryMedium font-spacing`}>Birthday</Text>
      <TextInput style={tw`mb-xs p-xs rounded-sm border border-dark text-lg text-dark font-primary font-spacing outline-none`}
        value={birthDay}
        keyboardType='number-pad'
        editable={false} />

      <Text style={tw`mb-xs text-lg text-dark-dark font-primaryMedium font-spacing`}>Email</Text>
      <TextInput style={tw`mb-xs p-xs rounded-sm border border-dark text-lg text-dark font-primary font-spacing outline-none`}
        value={email}
        editable={false}
        onChangeText={email => setEmail(email)} />

      <Text style={tw`mb-xs text-lg text-dark-dark font-primaryMedium font-spacing`}>Phone Number</Text>
      <TextInput style={tw`mb-xs p-xs rounded-sm border border-dark text-lg text-dark font-primary font-spacing outline-none`}
        value={phoneNum}
        keyboardType='phone-pad'
        editable={false}
        onChangeText={phoneNum => setPhoneNum(phoneNum)} />

      <Text style={tw`mb-xs text-lg text-dark-dark font-primaryMedium font-spacing`}>Current Password</Text>
      <TextInput style={tw`mb-xs p-xs border border-dark rounded-sm text-lg font-primary font-spacing outline-none`}
        onChangeText={password => setPassword(password)}
        secureTextEntry />

      <Text style={tw`mb-xs text-sm text-dark-dark font-primary font-spacing`}>
        Confirm your current password to submit, or enter old and new password to change your password.
      </Text>

      <Pressable onPress={() => setPasswordVisible(!passwordVisible)} style={tw`flex-row justify-between items-center`}>
        <Text style={tw`mb-xs text-lg text-dark-dark font-primaryMedium font-spacing`}>
          Update Password
        </Text>

        <Text style={tw`text-4xl text-gray-600 font-bold`}>
          {passwordVisible? "-" : "+"}
        </Text>
      </Pressable>

      {passwordVisible ? <>
        <Text style={tw`mb-xs text-lg text-dark-dark font-primaryMedium font-spacing`}>New Password</Text>
        <TextInput style={tw`mb-xs p-xs border border-dark rounded-sm text-lg font-primary font-spacing outline-none`}
          onChangeText={newPassword => setnewPassword(newPassword)}
          textContentType='newPassword'
          secureTextEntry />
      </> : null}

      <View style={tw`flex flex-row items-center mb-2.25`}>
        <Switch value={rememberMe}
          onValueChange={() => setRememberMe(!rememberMe)}
          trackColor={{false: tw`text-dark-light`.color, true: currentGradient[1]}}
          thumbColor={currentGradient[0]}
          ios_backgroundColor={{false: tw`text-dark-light`.color, true: currentGradient[1]}} />

        <View style={tw`mr-1.25 p-xs`}>
          <Text sylte={tw`text-lg text-dark-dark font-primaryMedium font-spacing`}>Remember Me</Text>
        </View>
      </View>

      {loading ? <LoadingSpinner/> : <Button onPress={() => handleSubmit()}
        color={currentGradient[0]}
        text='Save Changes' />}
    </View>
  </Page>
}
