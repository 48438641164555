import {useContext} from "react";
import {Svg, Circle, Path} from 'react-native-svg';

import Context from "../context.jsx";


export default function WineIcon({size, color, backgroundColor, style}) {
  const {tw} = useContext(Context);

  return <Svg xmlns="http://www.w3.org/2000/svg"
    style={tw.style(style ? style : null, {height: size, width: size})}
    fill={backgroundColor}
    viewBox="0 0 1026 1026"
    strokeWidth={1.5}
    stroke={backgroundColor}>

    <Circle fill={color}
      cx='50%'
      cy='50%'
      r='47%' />

    <Path transform="matrix(1 0 0 -1 0 963)"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M512 934.4c268.631 0 486.4-217.769 486.4-486.4s-217.769-486.4-486.4-486.4c-268.631 0-486.4 217.769-486.4 486.4s217.769 486.4 486.4 486.4zM542.421 742.4h-60.783l-0.102-202.217c-24.988-12.276-42.566-27.466-52.736-45.568s-16.57-33.292-19.2-45.568v0-385.047h204.858l-1.163 385.024c-3.126 11.379-9.138 29.751-18.037 45.591-10.17 18.102-27.748 33.292-52.736 45.568v0l-0.102 202.217zM542.3 832h-60.842l0.077-76.8h60.928l-0.164 76.8z" />
  </Svg>;
}
