import {useState, useEffect} from "react";
import * as Linking from "expo-linking";
import {useLocation, mobilePlatform} from "../utilities/router.jsx";


export default function useOpenedWithUrl(navigate) {
	const location = useLocation()

	const [openedWithUrl, setOpenedWithUrl] = useState(null)
	const [openedWithParams, setOpenedWithParams] = useState(null)
	const [openedWithUrlReady, setOpenedWithUrlReady] = useState(false)
	
	// Listen for deep links when app already open
	useEffect(() => {
		if (mobilePlatform) {
			function handleOpenUrl(event) {
				const url = stringToUrl(event.url)
				const params = stringToUrlParams(event.url)

				setOpenedWithUrl(url)
				setOpenedWithParams(params)
				setOpenedWithUrlReady(true)

				if (location.pathname !== url.pathname) navigate(url.pathname)
			}

			const listener = Linking.addEventListener('url', handleOpenUrl)
			return () => listener.remove()
		}
	})

	// Get initial url when opening app with deep link
	useEffect(() => {
		if (mobilePlatform) {
			const getInitialUrlAsync = async function() {
				const initialUrl = await Linking.getInitialURL()

				const url = stringToUrl(initialUrl)
				const params = stringToUrlParams(initialUrl)

				setOpenedWithUrl(url)
				setOpenedWithParams(params)
				setOpenedWithUrlReady(true)
				
				navigate(url.pathname)
			}

			getInitialUrlAsync()
		}
	}, [])

	return {openedWithUrl,
		openedWithParams,
		openedWithUrlReady}
}

function stringToUrl(url) {
	return new URL(url)
}

function stringToUrlParams(url) {
	url = new URL(url)
	const searchParams = new URLSearchParams(url.search)
	return Object.fromEntries(searchParams.entries())
}
