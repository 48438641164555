import {useState, useEffect, useRef} from 'react';
import {AppState} from 'react-native';

import usePrevious from './usePrevious';

export default function useAppState() {
  const appState = useRef(AppState.currentState)
  const activeState = appState.current
  const [toForeground, setToForeground] = useState(false)
  const [toBackground, setToBackground] = useState(false)
  const previousActiveState = usePrevious(activeState)

  const isActive = activeState.current === 'active'

  useEffect(function() {
    const subscription = AppState.addEventListener('change', onChange)
    
    function onChange(nextAppState) {
      appState.current = nextAppState

      const nextActive = nextAppState === 'active'
      const nextInactive = nextAppState === 'inactive'
      setToForeground(nextActive)
      setToBackground(nextInactive)}
    return function() {subscription?.remove()}
  })

  return {activeState, isActive, toForeground, toBackground}
}
