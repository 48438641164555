import {View, Text, Pressable, Image} from "react-native";
import * as ImagePicker from 'expo-image-picker';

import {LinearGradient} from "expo-linear-gradient";
import {useContext, useState} from "react";
import {ajaxRequest, uuid, pSBC} from "js-utilities";
// import parseNestedErrors from '../assets/functions/parseNestedErrors.js';

import {BlurView} from "../assets/functions/objects";
import XIcon from "./icons/xIcon.jsx";
import Button from './ui/button.jsx';

import Context from "./context.jsx";
import CameraModal from "./cameraModal.jsx";
import LoadingSpinner from "./ui/loadingSpinner.jsx";


export default function RedeemForm({offer, limitReached}) {
  const {tw,
    config,
    isDesktopWeb,
    navigate,
    auth,
    location,
    selectedState,
    analyticsEvent,
    currentGradient,
    displayStatusMessage,
    getMediaUploads} = useContext(Context)

  const [cameraVisible, setCameraVisible] = useState(false)
  const [images, setImages] = useState([])
  const [uploadingSubmission, setUploadingSubmission] = useState(false)

  function removeImage(image) {
    setImages(images.filter(function(img) {
      return img.uuid !== image.uuid
    }))}

  function pickImage() {
    ImagePicker.launchImageLibraryAsync({
      allowsMultipleSelection: true,
      base64: true,
      quality: 0.25,
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      orderedSelection: true,
      selectionLimit: 6,
      presentationStyle: ImagePicker.UIImagePickerPresentationStyle.FULL_SCREEN = "fullScreen"
    }).then(function(result) {
      if (result.canceled) return;
      const selectedImages = result.assets.map(function(asset) {
        const dataUri = `data:image/jpeg;base64,${asset.base64}`
        return {uuid: uuid(),
          base64: asset.base64,
          dataUri}})
      setImages([...images, ...selectedImages])
    }).catch(function() {
      displayStatusMessage({type: 'error',
        message: 'Something Went Wrong'})
    })}

  function handleSubmit() {
    if (uploadingSubmission) return;
    setUploadingSubmission(true)
    
    const requestData = {
			media_upload: {
				offer_id: offer.id,
				disbursement_method: 'payout_flow',
				region: selectedState.name,
        media_upload_images_attributes: images.map(image => {
          return {image_data_uri: image.dataUri}
        })
      }}

    if (!images.length) return displayStatusMessage({type: 'error',
      message: 'No Images Selected',
      details: 'An image is required to submit your receipt'})
    if (!auth.user.verified) return displayStatusMessage({type: 'error',
      message: 'Your account is not verified',
      details: 'Verify your account on the account page to redeem this offer'})
    if (auth.user.disabled) return displayStatusMessage({type: 'error',
      message: 'Your account has been disabled',
      details: 'Contact support@bybeapp.com if you believe this is an error'})

    ajaxRequest('POST', `${config.apiEndpoint}/media_uploads`, {
      body: requestData,
      params: {multiple_images: true},
      headers: auth.authHeaders,
      json: true
    }).then(() => {
      setUploadingSubmission(false)
      getMediaUploads()
      displayStatusMessage({type: 'success',
        message: 'Your receipt has been submitted',
        details: 'We aim to review every submission within 48 hours'})
      analyticsEvent('receiptUpload', {offerId: offer.id,
        offerName: offer.name,
        imagesCount: images.length,
        state: selectedState.name})
      navigate('/balance')
    }).catch(() => {
      setUploadingSubmission(false)
      displayStatusMessage({type: 'error',
        message: 'Oops, something went wrong',
        details: 'Please contact support@bybeapp.com, so we can fix the issue quickly!'})
      })
    }

  return <View style={tw`w-full rounded-sm bg-white bg-opacity-60 shadow overflow-hidden`}>
    {isDesktopWeb ? null : <BlurView style={tw.style(`absolute w-full h-full rounded-sm`)}
      opacity={0.75}
      blurAmount={24}
      blurType={'light'} />}

    <LinearGradient colors={[pSBC(-0, currentGradient[0]), pSBC(-0.5, currentGradient[1])]}
      style={tw.style(`absolute w-full h-full rounded-sm`, {
        opacity: 0.64,
      })}
      start={{x: 0.5, y: 0.1}}
      end={{x: 0.5, y: 0.9}}
      locations={[0, 1]} />

    <View style={tw`items-center p-base`}>
      {auth.user.disabled ? <Text style={tw.style(`pb-sm mb-sm border-b border-white text-6xl text-center text-white font-primaryMedium font-spacing-md`, {
        textShadowColor: tw`text-dark`.color,
        textShadowRadius: 1
      })}>Your Account has been Disabled, Please Contact Support@bybeapp.com</Text> 

      : limitReached ? <Text style={tw.style(`pb-sm mb-sm border-b border-white text-6xl text-center text-white font-primaryMedium font-spacing-md`, {
        textShadowColor: tw`text-dark`.color,
        textShadowRadius: 1
      })}>You have reached your limit for this offer</Text> 

      : <Text style={tw.style(`pb-sm mb-sm border-b border-white text-6xl text-center text-white font-primaryMedium font-spacing-md`, {
        textShadowColor: tw`text-dark`.color,
        textShadowRadius: 1
      })}>Upload your receipt</Text>}

      <Text style={tw`mb-0.75xs text-1.5sm text-center text-white font-spacing-sm`}>
        A full itemized receipt, including location, date, and transaction number is required.
      </Text>

      <Text style={tw`mb-sm text-1.5sm text-center text-white font-spacing-sm`}>
        Highlight or circle the qualifying products on your receipt to get your rebate faster.
      </Text>

      <View style={tw`flex-row justify-center w-full mb-sm`}>
        <Button onPress={() => setCameraVisible(!cameraVisible)}
          color={currentGradient[0]}
          text='Camera'
          disabled={auth.user.disabled || limitReached}
          extraStyle={tw`mr-xs`} />

        <Button onPress={pickImage}
          color={currentGradient[0]}
          text='Gallery'
          disabled={auth.user.disabled || limitReached} />
      </View>

      <CameraModal visible={cameraVisible}
        setVisible={setCameraVisible}
        images={images}
        setImages={setImages} />

      {images.length > 0 ? <>
        <View style={tw`flex-row flex-wrap justify-center w-full mb-sm`}>
          {images.map( image => <View key={`uploadedImage-${image.uuid}`} style={tw`w-1/3 aspect-square p-0.5xs`}>
            <Pressable style={tw.style(`absolute top-1.5xs right-1.5xs p-0.25xs rounded-full z-20 shadow-sm`, {backgroundColor: pSBC(-0.33, currentGradient[0])})}
              onPress={function() {removeImage(image)}}>
              <XIcon size={4} color={'white'} />
            </Pressable>

            <Image style={tw`w-full h-full rounded-sm bg-white shadow-sm`}
              source={{uri: image.dataUri}} />
          </View> )}
        </View>

        {uploadingSubmission ? <LoadingSpinner color={pSBC(0.33, currentGradient[0])} /> : <Button onPress={handleSubmit}
          color={currentGradient[0]}
          text='Submit'
          disabled={auth.user.disabled} />}
      </> : null}
    </View>
  </View>
}
