import {useState, useEffect, useCallback, useMemo} from 'react';
import {ajaxRequest} from "js-utilities";

import {useNavigate} from '../utilities/router';
import usePrevious from './usePrevious';
import useOffersCache from './useOffersCache';

export default function useOffers({config,
  store,
  toForeground,
  usStates,
  location,
  locationReady,
  locationError,
  selectedState,
  setSelectedState,
  previousSelectedState,
  selectedStateFromStoreReady,
  errors,
  setErrors}) {
  const navigate = useNavigate()

  const fetchCachedOffers = useOffersCache({store, config})

  const previousLocation = usePrevious(location)
  const previousSelectedStateFromStoreReady = usePrevious(selectedStateFromStoreReady)
  const previousToForeground = usePrevious(toForeground)

  const [offers, setOffers] = useState([])
  const [offersLoading, setOffersLoading] = useState(false)
  const [offersReady, setOffersReady] = useState(false)
  const [intinalOffersLoading, setInitialOffersLoading] = useState(true)
  const noLocation = Boolean(locationReady && !location)
  const noSelectedState = Boolean(selectedStateFromStoreReady && !selectedState)
  const previousSelectedStateExists = Boolean(previousSelectedState)
  const noLocationAndStateReady = Boolean(locationError && selectedStateFromStoreReady && selectedState && !previousSelectedStateFromStoreReady)
  const noLocationAndStateSelected = Boolean(locationError && selectedStateFromStoreReady && !previousSelectedState && selectedState)
  const selectedStateChanged = Boolean(selectedState?.name !== previousSelectedState?.name)
  const locationChanged = Boolean(location?.coords?.latitude !== previousLocation?.coords?.latitude ||
    location?.coords?.longitude !== previousLocation?.coords?.longitude)
  const validSelectedStateChange = Boolean(selectedState &&
    selectedStateChanged &&
    previousSelectedStateExists)
  const toForegroundAndReady = Boolean(toForeground && toForeground !== previousToForeground
    && ((locationReady && location) || (selectedStateFromStoreReady && selectedState)))
  const offersNeedUpdate = Boolean((location && locationChanged) || 
    noLocationAndStateReady ||
    noLocationAndStateSelected ||
    validSelectedStateChange)

  useEffect(() => {
    if (noLocation && noSelectedState) return navigate('/location')
    if (offersNeedUpdate) getOffers(validSelectedStateChange)
  }, [noLocation, noSelectedState, offersNeedUpdate, validSelectedStateChange])

  useEffect(() => {
    if (toForegroundAndReady) getOffers()
  }, [toForegroundAndReady])

  const getOffers = useCallback(function(forceSelectedState) {
    setOffersLoading(true);

	const params = {}
	if (forceSelectedState) params.state = selectedState?.name.toLowerCase()
	else if (location) {
		params.latitude = location.coords.latitude
		params.longitude = location.coords.longitude}
	else if (selectedState) params.state = selectedState?.name.toLowerCase()
	else {
		setOffersLoading(false)
		setOffersReady(true)
		setInitialOffersLoading(false)
		return}

	fetchCachedOffers(params)
		.then(function ({offers, geoState}) {
			if (offers?.length > 0) {
				const responseState = usStates[geoState]
				if (location && responseState?.name !== selectedState?.name){
					setSelectedState(responseState)
				}
				setOffers(offers)
				setOffersLoading(false)
				setOffersReady(true)
			} else {
				setErrors([...errors, {offers: 'No offers found'}])
				setOffersLoading(false)
				setOffersReady(true)
			}
		})

  //   ajaxRequest("GET", `${config.apiEndpoint}/offers`,
  //     {params, json: true})
  //     .then(function(response) {
  //       setOffers(response.body.offers)
  //       setOffersLoading(false)
  //       setOffersReady(true)
  //       setInitialOffersLoading(false)
  //       const responseState = usStates[response.headers.geolocationstate]
  //       if (location && responseState?.name !== selectedState?.name){
  //         setSelectedState(responseState)
  //       }
  //     })
  //     .catch(function(response) {
  //       setErrors([...errors, {offers: response.body}])
  //       setOffersLoading(false)
  //       setOffersReady(true)
  //       setInitialOffersLoading(false)
  //     })
  }, [location, selectedState, errors, setErrors, validSelectedStateChange])

  return {offers, offersLoading, offersReady, intinalOffersLoading, getOffers}
}
