import {useContext, useState, useEffect, useLayoutEffect, useRef} from "react";
import {View, SectionList, FlatList, RefreshControl, ActivityIndicator, Text, TextInput, TouchableOpacity} from "react-native";
import {LinearGradient} from "expo-linear-gradient";
import {useSpringValue} from "./utilities/wrapSpring";
import { FlashList } from "@shopify/flash-list";

import Context from "./context.jsx";

import AnimatedBlurPanel from './ui/animatedBlurPanel.jsx';

import Select from "./select.jsx";
import LoadingSpinner from "./ui/loadingSpinner.jsx";
import {FeaturedOfferListItem, OfferListItem} from "./offerListItem.jsx";
import TutorialModal from "./tutorialModal.jsx";
import XIcon from './icons/xIcon';


export default function Offers() {
  const {tw, log, searchQuery, setSearchQuery, typeFilter, setTypeFilter, sortBy, setSortBy, headerHeight, contentHeight, navigate, currentScreen, swipableViewProps, menuOpen, setCurrentGradient, scrollTop, setScrollTop, offersScrollPositionRef, offers, offersLoading, offersReady, getOffers, intinalOffersLoading, consumerBalances, consumerBalancesReady, sortAndFilterVisible} = useContext(Context);

  const [filteredOffers, setFilteredOffers] = useState(offers);

  const [typeFilterVisible, setTypeFilterVisible] = useState(false);
  const [sortByVisible, setSortByVisible] = useState(false);

  const offersListRef = useRef();

  const sortAndFilterBlurViewOpacity = useSpringValue(0, {config: {duration: 333}});
  const sortAndFilterLinearGradientOpacity = useSpringValue(0.24, {config: {duration: 333}});
  const sortAndFilterLinearGradientLocations = useSpringValue([0, 1], {config: {duration: 200}});

  useLayoutEffect(() => {
    const blurViewOpacity = menuOpen ? 1 : scrollTop ? 0 : 1;
    const linearGradientOpacity = menuOpen ? 0.64 : scrollTop ? 0.24 : 0.64;
    const linearGradientEnd = menuOpen ? [0, 0.56] : [0, 1];

    sortAndFilterBlurViewOpacity.start(blurViewOpacity);
    sortAndFilterLinearGradientOpacity.start(linearGradientOpacity);
    sortAndFilterLinearGradientLocations.start(linearGradientEnd);
  }, [menuOpen, scrollTop]);

  const productTypes = Object.values(offers.reduce((productTypes, offer) => {
    const productType = offer.required_products[0].products[0].product_type.name.toLowerCase();
    productTypes[productType] = {label: productType, value: productType};
    return productTypes;
  }, {}));

  useEffect(() => {
    setCurrentGradient([tw`text-primary-dark`.color, tw`text-primary`.color]);
    setScrollTop(true);

    return () => setScrollTop(false);
  }, []);

  function onOffersListLayout() {
    offersListRef.current?.scrollToOffset({
      offset: offersScrollPositionRef.current,
      animated: true})
  }

  useEffect(() => {
    setFilteredOffers(sortAndFilterOffers());
  }, [offers, typeFilter, sortBy, searchQuery]);

  useLayoutEffect(() => {
    if (typeFilterVisible) setSortByVisible(false);
  }, [typeFilterVisible]);

  useLayoutEffect(() => {
    if (sortByVisible) setTypeFilterVisible(false);
  }, [sortByVisible]);

  // TODO: Switch to other events from onScroll to avoid lag
  function handleScroll(event) {
    const scrollPosition = event.nativeEvent.contentOffset.y;
    offersScrollPositionRef.current = scrollPosition;
    if (scrollPosition > 0) setScrollTop(false);
    else setScrollTop(true);}

  function sortAndFilterOffers() {
		return offers.filter(offer => {
			if (typeFilter === 'all') return true;
      let match = offer.required_products[0].products.reduce( (match, requiredProduct) => {
        if (requiredProduct.product_type.name.toLowerCase() === typeFilter.toLowerCase()) match = true;
        return match;
      }, false);

		  return match;
    }).filter(offer => {
			let match = searchQuery === '' ? true : false;
			offer.required_products[0].products.forEach(requiredProduct => {
        if (requiredProduct.name.toLowerCase().includes(searchQuery.toLowerCase())) match = true;
			});

			return match;
		}).sort( (a, b) => {
      switch (sortBy) {
        case 'new':
          return Math.abs(new Date( a.start_date ).getTime() - new Date().getTime())
            - Math.abs(new Date( b.start_date ).getTime() - new Date().getTime());
        case 'endingSoon':
          return new Date(a.end_date) - new Date(b.end_date);
        case 'valueDesc':
          return b.discount_cents - a.discount_cents;
        case 'valueAsc':
          return a.discount_cents - b.discount_cents;
        default:
          return new Date(b.created_at) - new Date(a.created_at);}
    });
  }

  const numColumnsMap = {'xs': 1,'sm': 1,'md': 2,'lg': 3, 'xl': 4};
  const numColumns = numColumnsMap[currentScreen];
  const featuredHeight = tw`h-72`.height
  const featuredXPadding = tw`pt-0.75xs`.paddingTop
  const featuredWidth = tw`w-52`.width + featuredXPadding
  const offerYPadding = tw`p-0.5xs`.paddingTop
  const offerHeight = (numColumns > 1 ? tw`h-32`.height : tw`h-34`.height) + (offerYPadding * 2)

  const offerListItemProps = {tw,
    log,
    navigate,
    consumerBalances,
    consumerBalancesReady,
    numColumns,
    offerHeight,
    offerYPadding,
    featuredHeight};

  const offersListData = [{featuredOffers: filteredOffers.filter(offer => offer.featured)},
    {normalOffers: filteredOffers.filter(offer => !offer.featured)}];

  const moreOffersHeight = offersListData[1].normalOffers.length * offerHeight

  // TODO: Featured offers horizontal list with 2 columns
  // TODO: More offers vertical list with 1 column
  // TODO: When searching use a flat list of all offers styled like more offers
  return <LinearGradient colors={[tw`text-primary-dark`.color, tw`text-blue-accent`.color, tw`text-primary`.color]}
    locations={[0.3, 0.6, 0.9]}
    start={{x: 0, y: 1}}
    end={{x: 1, y: 0}}
    style={tw`w-full h-[${headerHeight + contentHeight}px]`}
    {...(menuOpen ? {} : swipableViewProps)}>

    <AnimatedBlurPanel style={tw.style(`flex-row items-center justify-between absolute top-[${headerHeight}px] w-full h-14 z-10 ${scrollTop ? '' : 'shadow'}`, {transform: []})}
      blurViewOpacity={sortAndFilterBlurViewOpacity}
      linearGradientOpacity={sortAndFilterLinearGradientOpacity}
      gradientLocations={sortAndFilterLinearGradientLocations}
      hidden={!sortAndFilterVisible}
      showOverflow>

      <View style={tw`flex-row justify-between w-full`}>
        <View style={tw`flex-row flex-1 max-w-54 min-w-16  mx-base`}>
          <View style={tw`flex-row w-full items-center`}>
            <TextInput style={tw.style(`z-10 w-full px-xs py-0.5xs rounded bg-white text-base text-dark font-primary font-spacing outline-none`)}
              value={searchQuery}
              onChangeText={setSearchQuery}
              placeholder='Search'
              placeholderTextColor={tw`text-dark`.color}
              />
            <TouchableOpacity style={tw.style(`absolute right-2 z-20 bg-white`)} width={24} height={24} onPress={() => setSearchQuery('')}>
              {searchQuery.length > 0 && (<XIcon size={4} strokeWidth={2.5} color={tw`text-black`.color}/>)}
            </TouchableOpacity>
          </View>
        </View>

        <View style={tw`flex-row justify-end`}>
          <Select id='typeFilter' style={tw`mr-2`} data={[{label: 'All', value: 'all'}, ...productTypes]}
            value={typeFilter} setValue={setTypeFilter}
            visible={typeFilterVisible} setVisible={setTypeFilterVisible}
            label='Filter' />

          <Select id='sortBy' style={tw`mr-base`}
            data={[{label: 'New', value: 'new'},
              {label: 'Ending Soon', value: 'endingSoon'},
              {label: 'High to Low', value: 'valueDesc'},
              {label: 'Low to High', value: 'valueAsc'}]}
            value={sortBy} setValue={setSortBy}
            visible={sortByVisible} setVisible={setSortByVisible}
            label='Order' />
        </View>
      </View>
    </AnimatedBlurPanel>

    {offersLoading && offersReady ? <LoadingSpinner size={33} extraStyle={tw`absolute top-[80px] max-h-[256px] w-full`} /> : null}
    {offersReady && filteredOffers?.length ? <View style={tw`flex-1 w-full justify-center`}>
      <FlatList ref={offersListRef}
        contentContainerStyle={tw`px-base pb-sm pt-[${
          headerHeight + (sortAndFilterVisible ? tw`h-14`.height : 0) + tw`pt-0.5xs`.paddingTop
        }px]`}
        data={offersListData}
        estimatedItemSize={moreOffersHeight}
        onLayout={onOffersListLayout}
        onScroll={handleScroll}
        scrollEventThrottle={50}
        refreshControl={<RefreshControl refreshing={offersLoading} onRefresh={getOffers} />}
        renderItem={({item}) => {return item.featuredOffers?.length ? <View style={tw`h-86`}>
          <View style={tw`flex-row items-center h-14`}>
            <Text style={tw.style(`text-4.5xl text-white font-bold font-spacing-md`)}>
              Featured
            </Text>
          </View>
          <View style={tw`h-72 overflow-hidden`}>
            <FlashList horizontal
              numColumns={1}
              data={item.featuredOffers}
              estimatedItemSize={featuredWidth}
              keyExtractor={featuredOffer => `featuredOffer-${featuredOffer.id}`}
              renderItem={({item, index}) => <FeaturedOfferListItem
              index={index}
              offer={item}
              {...offerListItemProps} />} />
          </View>
        </View> : item.normalOffers?.length ? <View>
          <View style={tw`flex-row items-center h-14`}>
            <Text style={tw.style(`text-4.5xl text-white font-bold font-spacing-md`)}>
              More Offers
            </Text>
          </View>
          <View style={tw`w-full min-h-[${Math.ceil(moreOffersHeight / numColumns)}px]`}>
            <FlatList key={currentScreen}
              data={item.normalOffers}
              estimatedItemSize={144}
              numColumns={numColumns}
              keyExtractor={normalOffer => `normalOffer-${currentScreen}-${normalOffer.id}`}
              renderItem={({item, index}) => <OfferListItem
              index={index}
              offer={item}
              {...offerListItemProps} />} />
          </View>
        </View> : null}} />
      </View> : offersLoading || intinalOffersLoading ? <LoadingSpinner /> : <View style={tw`w-full h-full items-center justify-center`}>
        <Text style={tw.style(`text-4.5xl text-white font-bold font-spacing-md`)}>
          No offers available
        </Text>
      </View>}
  </LinearGradient>;
}
