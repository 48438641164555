import {useContext} from "react";
import {Svg, Path} from 'react-native-svg';

import Context from "../context.jsx";


export default function ChevronRightIcon({size, color, extraStyle}) {
  const {tw} = useContext(Context);

  return <Svg xmlns="http://www.w3.org/2000/svg"
    style={tw`w-${size} h-${size} ${extraStyle}`}
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke={color}>
    <Path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
  </Svg>;
}
