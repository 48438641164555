import {View, Text, Pressable} from 'react-native';

import {LinearGradient} from 'expo-linear-gradient';
import {BlurView} from "../assets/functions/objects";
import {pSBC} from "js-utilities";

import {useContext} from 'react';
import Context from './context.jsx';

import Button from './ui/button.jsx';


export default function LoginCallout() {
  const {isDesktopWeb,tw, navigate, currentGradient} = useContext(Context);

  return <View style={tw`w-full rounded-sm bg-white bg-opacity-60 shadow overflow-hidden`}>
    {isDesktopWeb ? null : <BlurView style={tw.style(`absolute w-full h-full rounded-sm`)}
      opacity={0.75}
      blurAmount={24}
      blurType={'light'} />}

    <LinearGradient colors={[...currentGradient]}
      style={tw.style(`absolute w-full h-full rounded-sm`, {
        opacity: 0.64,
      })}
      start={{x: 0.5, y: 0.1}}
      end={{x: 0.5, y: 0.9}}
      locations={[0, 1]} />

    <View style={tw`items-center p-base`}>
      <Text style={tw.style(`pb-sm mb-sm border-b border-white text-6xl text-center text-white font-primaryMedium font-spacing-md`, {
        textShadowColor: tw`text-dark`.color,
        textShadowRadius: 1
      })}>Login or Sign Up</Text>

      <Text style={tw`mb-sm text-1.5sm text-center text-white font-spacing-sm`}>
        You must have an account to redeem this offer
      </Text>

      <View style={tw`flex-row flex-wrap justify-center mb-xs`}>
        <Button onPress={() => navigate('/login')}
          color={currentGradient[0]} text='Login'
          extraStyle={tw`mr-sm`} />
        <Button onPress={() => navigate('/signup')}
          color={currentGradient[0]} text='Sign Up' />
      </View>
    </View>
  </View>;
}
