import {useState, useEffect} from 'react';

import {ajaxRequest} from "js-utilities";

import usePrevious from './usePrevious';


export default function useConsumerBalances(config, toForeground, auth, errors, setErrors) {
  const [consumerBalances, setConsumerBalances] = useState([]);
  const [consumerBalancesLoading, setConsumerBalancesLoading] = useState(false);
  const [consumerBalancesReady, setConsumerBalancesReady] = useState(false);

  const previousAuth = usePrevious(auth);

  useEffect(() => {
    if (auth) getConsumerBalances()
  }, [toForeground, auth])

  useEffect(() => {
    if (previousAuth && !auth) setConsumerBalances([])
  }, [auth, previousAuth])

  function getConsumerBalances() {
    if (!auth) return
    setConsumerBalancesLoading(true);

    ajaxRequest("GET", `${config.apiEndpoint}/consumer_balances`, {
      json: true,
      headers: auth.authHeaders
    }).then(response => {
      setConsumerBalances(response.body.consumer_balances);
      setConsumerBalancesLoading(false);
      setConsumerBalancesReady(true);
    }).catch(response => {
      setErrors([...errors, response.body]);
      setConsumerBalancesLoading(false);
      setConsumerBalancesReady(true);
    });
  }

  return {consumerBalances,
    consumerBalancesLoading,
    consumerBalancesReady,
    getConsumerBalances};
}
