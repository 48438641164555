import { useContext } from "react";
import {View, Text} from "react-native";

import Context from "./context.jsx";

import MediaUpload from "./mediaUpload.jsx";


export default function MediaUploadsList({type,
  title,
  mediaUploads,
  initiallyExpandedIndexes=[]}) {
  const {tw} = useContext(Context);

  return <View style={tw`w-full`}>
    <View style={tw`mb-base py-sm border-b-2 border-primary-dark`}>
      <Text style={tw`text-4xl text-left text-dark-dark font-primaryMedium leading-normal`}>{title}</Text>
    </View>

    {mediaUploads.length === 0 ? <Text style={tw`text-center text-white`}>No Balance History</Text> : null}

    {mediaUploads.length ? mediaUploads.map((mediaUpload, index) => <MediaUpload key={`mediaUpload_${type}_${index}`}
      type={type}
      mediaUpload={mediaUpload}
      initiallyExpanded={initiallyExpandedIndexes.includes(index)}
      lastItem={index === mediaUploads.length - 1} />)
    : <Text style={tw`mb-xs pb-sm text-center text-lg text-dark-dark font-primaryMedium`}>No Submissions</Text>}
  </View>;
}
