import {useEffect, useContext} from 'react';
import {View} from 'react-native';

import Context from "./context.jsx";

import FaqListItem from './faqListItem.jsx';
import Page from './ui/page.jsx';
import LoadingSpinner from "./ui/loadingSpinner.jsx";

export default function Faq() {
  const {tw, faq, faqLoading, faqReady, getFaq} = useContext(Context);

  useEffect(() => getFaq, []);

  return <Page header={'FAQ'}>
    <View style={tw`w-full`}>
      {faqReady && !faqLoading ? faq.map(faq => <FaqListItem key={`faq_${faq.id}`} faq={faq} /> )
      : <LoadingSpinner />}
    </View>
  </Page>
}
