import {useEffect} from 'react'
import {ajaxRequest} from 'js-utilities'


export default function useOffersCache({store, config}) {
  const {get, set} = store

  const url = `${config.apiEndpoint}/offers`
  const metaKeyMap = {i: 'id', u: 'updated_at'}

  // const urlSearchParams = new URLSearchParams(params);
  // urlSearchParams.set('ids', offerIds.join(','));
  // const query = urlSearchParams.toString();
  // const fullUrl = `${url}?${query}`;

  // const [cachedResources, setCachedResources] = useState([])
  // const [loading, setLoading] = useState(false)
  // const [error, setError] = useState(false)

  // useEffect(function () {
  //   setLoading(true)
  //   fetchCachedOffers()
  //     .then(function ({offers,
  //       addedSuccess,
  //       addedError}) {

  //       if (offers.length > 0)
  //         setCachedResources(offers)
  //       if (addedError.length > 0)
  //         setError(addedError)
  //       setLoading(false)
  //     })
  // }, [resources, store])

  function fetchCachedOffers(params) {
    return new Promise(function (resolve) {
      // - Make request to get offer ids and updated timestamps
      getOffersMeta(params)
        .then(function ({ metaOffers, geoState, error }) {
          const getCachePromises = metaOffers
            .map(function({i, u}) {
              return getCachedOffer(i, u)
                .then(function ({ offer }) {
                  if (offer) return {offer}
                  else return {[metaKeyMap['i']]: i}
                })
            })
          return Promise.all(getCachePromises)
        .then(function (results) {
          const offers = results
            .filter(function ({offer}) {return offer})
            .map(function ({offer}) {return offer})
          const offerIdsToGet = results
            .filter(function ({id}) {return id})
            .map(function ({id}) {return id})
          // all offers found in cache
          if (offerIdsToGet.length === 0)
            return resolve({offers, geoState})
          getOffersByIds(offerIdsToGet, params)
            .then(function ({offers, error}) {
              if (error) return resolve({error})
              const setCachePromises = offers.map(function (offer) {
                return new Promise(function (resolve) {
                  const key = getCacheKey(offer.id, offer.updated_at)

                  set(key, offer)
                    .then(function({data: offer, error}) {
                      if (error) return resolve({error})
                      else return resolve({offer})
                    })
                })
              })

              return Promise.all(setCachePromises)
            })
            .then(function(results) {
              const addedSuccess = results
                .filter(function ({offer}) {return offer})
                .map(function ({offer}) {return offer})
              const addedError = results
                .filter(function ({error}) {return error})
                .map(function ({error}) {return error})
              offers.push(...addedSuccess)
              resolve({offers, geoState, addedSuccess, addedError})
            })
          })
        })
    })
  }

  // Make request to get offer ids and updated timestamps
  function getOffersMeta(params) {
    // params = {minify_keys: minifyKeys.join(','), ...params}
    // params = Object.assign({minify_keys: minifyKeys.join(',')}, {...params})
    const config = {params: {
      meta: true,
      ...params
    }, json: true}

    return new Promise(function (resolve) {
      ajaxRequest("GET", url, config)
        .then(function (response) {
          const {body, headers} = response
          const metaOffers = body?.offers
          const geoState = headers?.['geolocationstate']

          resolve({ metaOffers, geoState })})
        .catch(function ({body}) {
          // TODO: Refactor ajaxRequest to not use reject
          // TODO: Expected errors
          resolve({error: body})
        })
    })
  }

  // Cache key > id[ID].updated[UPDATED]
  function getCacheKey(id, updated) {
    return `bybeapp.offer.id-[${id}].updated-[${updated}]`
  }

  // returns {offer, error}
  // Todo get possible errors from useStore
  // possible error.name > Not in cache or expired, Cache error
  function getCachedOffer(id, updatedAt) {
  // Use get function to check cache
    return new Promise(function (resolve) {
      get(getCacheKey(id, updatedAt))
        .then(function ({ data: offer, error }) {
          if (error) return resolve({error})
          return resolve({offer})
        })
        .catch(function (error) {
          // leave empty
          // we don't use .catch
      })
    })
  }

  // Make request to get offers by ids
  function getOffersByIds(offerIds, params) {
    return new Promise(function (resolve, reject) {
      params = {...params,
        ids: offerIds.join(',')}

      ajaxRequest("GET", url, {params, json: true})
        .then(function ({body: {offers}}) {
          //   - For each offer in response
          //     - Add offer to offers array
          //     - Use set to add offer to cache with key > id[ID].updated[UPDATED]
          resolve({offers})
        })
        .catch(function (error) {
          // do not use reject here
          // we don't use .catch
          resolve({error})
        })
    })
  }

  return fetchCachedOffers
  // return {cachedResources, loading, error}
}

// Pseudo code for offer cache
//   Get offers function

// - Define full offers objects array
// - Define offer ids to get array

//   - Cache key > id[ID].updated[UPDATED]
//   - Make request to get offer ids and updated timestamps

//     - For each offer meta item in response

//       - Use get function to check cache

//         - If key exists

//           - Add cache value to offers array
//           - Continue

//         - Else

//           - Add id to ids array
//           - Continue

//   - Make request to get offers by ids

        // ...

//   - Return offers array
