import {useState, useEffect} from 'react';
import {Dimensions} from "react-native";

import twConfig from '../../tailwind.config.js';


const screens = Object.entries(twConfig.theme.screens).map(screen => {
  return {size: screen[0], width: Number(screen[1].replace('px', ''))};
}).sort((a, b) => b.width - a.width);

export default function useCurrentScreen() {
  const dimensions = Dimensions.get('window');

  const [currentScreen, setCurrentScreen] = useState();

  useEffect(() => {
    setCurrentScreen(screens.find(screen => dimensions.width > screen.width)?.size || 'xs');
  }, [dimensions.width]);

  return currentScreen;
}
