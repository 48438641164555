import {useState, useContext} from "react";
import {View, Text, Pressable} from "react-native";

import {formatMoneyCondensed} from "js-utilities";

import ChevronUpIcon from './icons/chevronUpIcon.jsx';
import ChevronDownIcon from './icons/chevronDownIcon.jsx';
import Context from "./context.jsx";
import Purchase from "./purchase";


export default function ConsumerBalance({consumerBalance, initiallyExpanded, lastItem}) {
  const {tw, currentGradient} = useContext(Context);

  const [expanded, setExpanded] = useState(initiallyExpanded);

  const formatRedemptionDisbursementStatus = status => {
    switch(status) {
      case 'pending':
        return 'pending';
      case 'completed':
        return 'completed';
      case 'completed_partial_redemptions':
        return 'completed';
  
      case 'failed_disbursement_method':
        return 'failed';
      case 'failed_no_redemptions':
        return 'failed';
      case 'failed_unsafe_to_redeem':
        return 'failed';
      case 'failed':
        return 'failed';
  
      default:
        return status;}}

  return <View style={tw`bg-white rounded py-xs`}>
    <Pressable onPress={() => setExpanded(!expanded)}
      style={tw`flex-row items-center rounded-md border bg-white border-light shadow p-xs`}>

      <Text style={tw`mr-sm text-left text-lg text-green-accent font-primaryBold`}>
        {consumerBalance.balance_cents > 0 ? formatMoneyCondensed(consumerBalance.balance_cents) : formatMoneyCondensed(consumerBalance.value_cents)}
      </Text>

      <Text style={tw`text-left font-primaryMedium`}>
        {toCapitalized(formatRedemptionDisbursementStatus(consumerBalance.status))}
      </Text>

      <View style={tw`flex-row items-center ml-auto`}>
        {expanded ? <ChevronUpIcon color={tw`text-[${currentGradient[0]}]`.color} size={5} />
        : <ChevronDownIcon color={tw`text-[${currentGradient[0]}]`.color} size={5} /> }
      </View>
    </Pressable>

    {expanded ? consumerBalance.redemptions.map((redemption, index) => {
      return <Purchase key={`consumerBalance_${redemption.retailer_identifier}_purchase_${index}`}
        redemption={redemption}
        lastItem={index === consumerBalance.redemptions.length - 1} />;
      }) : null }
  </View>;
}

function toCapitalized(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);}
