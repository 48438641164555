import {useContext} from "react";
import {View, Text} from "react-native";

import Context from "./context.jsx";

import Page from './ui/page.jsx';
import Button from "./ui/button.jsx";


export default function AgePage() {
  const {tw, navigate, age, handleAtLeast21, handleNot21, afterAgePath} = useContext(Context);
  
  return <Page>
    <View style={tw`w-full`}>
      {age === 'not21' ? <Text style={tw`text-6xl text-center text-dark-dark font-primaryMedium font-spacing-md`}>
        You must be at least 21 to use this app.
      </Text> : <>
        <View style={tw`justify-center items-center w-full px-base mb-base`}>
          <Text style={tw.style(`text-6xl text-center text-dark-dark font-primaryMedium font-spacing-md`, {
            textShadowColor: tw`text-light-light`.color,
            textShadowRadius: 1
          })}>Are You At Least 21?</Text>
        </View>

        <View style={tw`flex-row justify-center`}>
          <Button color={tw`text-primary-dark`.color}
            extraStyle={tw`mr-xs`}
            text='Yes'
            onPress={() => {
              handleAtLeast21();
              navigate(afterAgePath);}} />

          <Button color={tw`text-primary-dark`.color}
            text='No'
            onPress={handleNot21} />
        </View>
      </>}
    </View>
  </Page>
}
