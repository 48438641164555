import {useContext} from "react";
import {Svg, Circle, Path} from 'react-native-svg';

import Context from "../context.jsx";


export default function LowToHighIcon({size, color, backgroundColor, style}) {
  const {tw} = useContext(Context);

  return <Svg xmlns="http://www.w3.org/2000/svg"
    style={tw.style(style ? style : null, {height: size, width: size})}
    fill={backgroundColor}
    viewBox="0 0 1026 1026"
    strokeWidth={1.5}
    stroke={backgroundColor}>

    <Circle fill={color}
      cx='50%'
      cy='50%'
      r='47%' />

    <Path transform="matrix(1 0 0 -1 0 963)"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M512 934.4c268.631 0 486.4-217.769 486.4-486.4s-217.769-486.4-486.4-486.4c-268.631 0-486.4 217.769-486.4 486.4s217.769 486.4 486.4 486.4zM544.95 652.8h-52.85v-54.726l-8.009-0.921c-31.546-4.219-57.226-14.866-77.041-31.941-21.467-18.498-32.2-41.897-32.2-70.198 0-18.924 4.2-35.248 12.6-48.972s21.175-25.914 38.325-36.57c17.15-10.655 43.575-21.78 79.275-33.373 18.2-7.16 31.267-14.832 39.2-23.016s11.9-19.35 11.9-33.501c0-13.639-4.025-24.337-12.075-32.094s-19.192-11.636-33.425-11.636c-17.733 0-31.033 5.413-39.9 16.239-7.98 9.743-12.369 23.25-13.167 40.519v0l-0.133 5.896h-99.050l0.146-6.863c1.365-31.596 12.283-57.14 32.754-76.633s48.971-31.745 85.5-36.757v0l7.95-0.963v-50.89h52.5v50.89l8.366 0.955c32.896 4.334 58.982 14.841 78.259 31.523 20.883 18.072 31.325 41.514 31.325 70.326 0 18.413-3.967 34.353-11.9 47.822s-20.125 25.53-36.575 36.186c-16.45 10.655-43.342 22.462-80.675 35.419-20.3 8.695-34.067 17.049-41.3 25.062s-10.85 18.072-10.85 30.176c0 13.98 3.5 24.891 10.5 32.734s16.683 11.764 29.050 11.764c12.833 0 23.275-5.029 31.325-15.088 7.245-9.053 11.23-22.318 11.954-39.794v0l0.121-5.982h99.050l-0.149 6.819c-1.292 29.174-10.984 53.497-29.076 72.969s-42.873 32.035-74.344 37.689v0l-7.381 1.182v55.749zM518.212 806.4l-108.612-108.612h217.223l-108.612 108.612z" />
  </Svg>;
}
