import {useContext, useState} from "react";
import {View, Text, TextInput} from "react-native";
import {ajaxRequest} from "js-utilities";

import Context from "./context.jsx";

import Page from './ui/page.jsx';
import Button from "./ui/button.jsx";
import LoadingSpinner from "./ui/loadingSpinner.jsx";


export default function NewPassword() {
  const {tw, config, isAndroid, isIos, routerLocation, navigate, currentGradient, displayStatusMessage, openedWithParams, openedWithUrlReady} = useContext(Context);

  let params, ready
  if (isAndroid || isIos) {
    params = openedWithParams
    ready = openedWithUrlReady
  } else {
    const searchParams = new URLSearchParams(routerLocation.search)
    params = Object.fromEntries(searchParams.entries())
    ready = true}

  const {uid, client, accesstoken} = getTokensFromParams(params, ready)

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [loading, setLoading] = useState(false);

  function handleSubmit() {
    setLoading(true);
    if (password !== passwordConfirmation) {
      setLoading(false);
      return displayStatusMessage({type: 'error', message: 'Invalid password', details: 'Passwords do not match.'});}

    ajaxRequest('PUT', `${config.apiEndpoint}/auth/password?password=${encodeURIComponent(password)}`, {
			headers: [
				{'uid': uid},
				{'client': client},
				{'access-token': accesstoken}
			]
		}).then( response => {
      setLoading(false);
      displayStatusMessage({type: 'success', message: 'Password Reset', details: 'Your password has been reset.'});
      navigate('/');
		}).catch( error => {
      setLoading(false);
			displayStatusMessage({type: 'error', message: 'Something Went Wrong'})});
  }

  return <Page header='Enter New Password'>
    <View style={tw`w-full`}>
      <Text style={tw`mb-xs text-lg text-dark-dark font-primaryMedium font-spacing`}>New Password</Text> 
      <TextInput style={tw`mb-xs p-xs rounded-sm border border-dark text-lg font-primary font-spacing outline-none`}
        value={password}
        onChangeText={password => setPassword(password)}
        secureTextEntry />

      <Text style={tw`mb-xs text-lg text-dark-dark font-primaryMedium font-spacing`}>Confirm New Password</Text> 
      <TextInput style={tw`mb-base p-xs rounded-sm border border-dark text-lg font-primary font-spacing outline-none`}
        value={passwordConfirmation}
        onChangeText={confirmPassword => setPasswordConfirmation(confirmPassword)}
        secureTextEntry />

      {loading ? <LoadingSpinner/> : <Button onPress={handleSubmit}
        color={currentGradient[0]}
        text='Set New Password' />}
    </View>
  </Page>;
}

function getTokensFromParams(params, ready) {
  if (!ready) return {}
  let uid, client, accesstoken

  uid = params.uid
  client = params.client
  accesstoken = params.accesstoken || params['access-token']

  return {uid, client, accesstoken}
}
