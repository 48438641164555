import {useContext, useState} from "react";
import {Text, View, Pressable} from "react-native";

import Context from "./context.jsx";
import ChevronDownIcon from "./icons/chevronDownIcon.jsx";
import ChevronUpIcon from "./icons/chevronUpIcon.jsx";

export default function FaqListItem(faq){
  const {tw, currentGradient} = useContext(Context);

  const [expanded, setExpanded] = useState(false);

  return <View style={tw`pb-sm w-full`} >
    <Pressable onPress={() => setExpanded(!expanded)}>
      <View style={tw`w-3/4`}>
        <Text style={tw`text-2xl pb-xs`}>{faq.faq.question}</Text>
      </View>
      {expanded ? <Text style={tw`text-lg ml-sm leading-relaxed`}>{faq.faq.answer}</Text> : null}
      <View style={tw`absolute right-0`}>
        {expanded ? <ChevronUpIcon color={tw`text-[${currentGradient[0]}]`.color} size={5} /> 
          : <ChevronDownIcon color={tw`text-[${currentGradient[0]}]`.color} size={5} />}
      </View>
    </Pressable>
  </View>
}
