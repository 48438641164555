import {View} from "react-native";
import {Component} from "react";


class BlurView extends Component {
  render() {
    const {blurAmount=24, blurType='dark', style, ...rest} = this.props;
    let backgroundColor;

    switch (blurType) {
      case 'light':
        backgroundColor = 'rgba(112,112,112, 0.25)';
        break;
      case 'dark':
        backgroundColor = 'rgba(0, 0, 0, 0.5)';
        break;
      default:
        backgroundColor = 'rgba(0, 0, 0, 0.5)'; }

    return <View {...rest} style={{...style, ...{
      backdropFilter: `saturate(125%) blur(${blurAmount}px)`,
      backgroundColor
    }}} />;
  }
}

function Grayscale({children, amount}) {
  return <View style={{filter: `grayscale(${amount*100}%)`}}>
    {children}
  </View>;
}

export {BlurView, Grayscale};
