import React, {useContext} from "react"
import {Text, Pressable, View} from "react-native";

import Context from "./context.jsx";

import AllIcon from "./icons/allIcon.jsx";
import WineIcon from "./icons/wineIcon.jsx";
import BeerIcon from "./icons/beerIcon.jsx";
import SpiritsIcon from "./icons/spiritsIcon.jsx";
import NewIcon from "./icons/newIcon.jsx";
import EndingSoonIcon from "./icons/endingSoonIcon.jsx";
import HighToLowIcon from "./icons/highToLowIcon.jsx";
import LowToHighIcon from "./icons/lowToHighIcon.jsx";


export default function Select({id, data, label, value, setValue, visible, setVisible, style}) {
  const {tw} = useContext(Context);

  const renderedIcon = () => {
    switch (value) {
      case 'new':
        return <NewIcon size={34} color={tw`text-white`.color} backgroundColor={tw`bg-blue-alt`.backgroundColor} />
      case 'endingSoon':
        return <EndingSoonIcon size={34} color={tw`text-white`.color} backgroundColor={tw`bg-orange-accent`.backgroundColor} />
      case 'valueDesc':
        return <HighToLowIcon size={34} color={tw`text-white`.color} backgroundColor={tw`bg-green-accent`.backgroundColor} />
      case 'valueAsc':
        return <LowToHighIcon size={34} color={tw`text-white`.color} backgroundColor={tw`bg-green-light`.backgroundColor} />
      case 'all':
        return <AllIcon size={34} color={tw`text-white`.color} backgroundColor={tw`text-green-accent`.color} />
      case 'beer':
        return <BeerIcon size={34} color={tw`text-white`.color} backgroundColor={tw`bg-brown`.backgroundColor} />
      case 'wine':
        return <WineIcon size={34} color={tw`text-white`.color} backgroundColor={tw`bg-violet`.backgroundColor} />
      case 'spirit':
        return <SpiritsIcon size={34} color={tw`text-white`.color} backgroundColor={tw`bg-blue-alt`.backgroundColor} />
      default:
        return <Text style={tw`mr-1 font-semibold`}>{selected.label}</Text>
    }
  }

  return <View style={style}>
    <Pressable onPress={() => setVisible(!visible)} style={tw`flex-row items-center`}>
      <Text style={tw`mr-1 text-sm text-white font-semibold tracking-wide`}>{label}</Text>
      {renderedIcon()}
    </Pressable>

    {visible ? <View style={tw.style(`flex-col absolute top-11 right-0 rounded-b-md p-1 bg-primary-dark shadow`)}>

      {data.map((option, index) => {
        return <Pressable key={`select-${id}-${option.value}`}
          style={tw`${index < data.length - 1 ? 'mb-1' : null}`}
          onPress={() => {
            setValue(option.value);
            setVisible(!visible);
          }}>
          <Text style={tw.style(`text-sm p-3 w-full text-center text-white font-medium capitalize`)}
            numberOfLines={1}>
            {option.label}
          </Text>
        </Pressable>;
      })}

    </View> : null}
  </View>;
}
