import {createContext, useContext, useState, useEffect, useRef} from "react";
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {useFonts} from "expo-font";

import tw from "../assets/functions/twInit.js";
import {useLocation as routerUseLocation} from "./utilities/router.jsx";
import NavigateContext from "./utilities/navigateContext.jsx";

import {config} from "../bybe.config.json";
import usStates from "../assets/usStates.json";
import pathToPageName from '../lib/pathToPageName.js';

import useFirebaseAnalytics from './hooks/useFirebaseAnalytics.jsx';
import useOpenedWithUrl from './hooks/useOpenedWithUrl.jsx';
// import useSwipe from "./hooks/useSwipe.jsx";
import useAppState from './hooks/useAppState.jsx';
import usePlatform from './hooks/usePlatform.jsx';
import useDimensions from './hooks/useDimensions.jsx';
import useCurrentScreen from './hooks/useCurrentScreen.jsx';
import usePrevious from './hooks/usePrevious.jsx';
import useStore from "./hooks/useStore.jsx";
import useTokenAuth from "./hooks/useTokenAuth.jsx";
import useGeolocation from "./hooks/useGeolocation.jsx";
import useSelectedState from "./hooks/useSelectedState.jsx";
import useAgeGate from './hooks/useAgeGate.jsx';
import useOffers from "./hooks/useOffers.jsx";
import useConsumerBalances from "./hooks/useConsumerBalances.jsx";
import useMediaUploads from "./hooks/useMediaUploads.jsx";
import useFaq from "./hooks/useFaq.jsx";

import useDebugLogger from './hooks/useDebugLogger.jsx';

const Context = createContext();

export function Provider({children}) {
  const debug = false
  const {ga4MeasurementId} = config

  const {navigate} = useContext(NavigateContext);
  const [fontsLoaded] = useFonts({
    'RobotoCondensed-Bold': require('../assets/fonts/RobotoCondensed-Bold.ttf'),
    'RobotoCondensed-BoldItalic': require('../assets/fonts/RobotoCondensed-BoldItalic.ttf'),
    'RobotoCondensed-Italic': require('../assets/fonts/RobotoCondensed-Italic.ttf'),
    'RobotoCondensed-Light': require('../assets/fonts/RobotoCondensed-Light.ttf'),
    'RobotoCondensed-LightItalic': require('../assets/fonts/RobotoCondensed-LightItalic.ttf'),
    'RobotoCondensed-Regular': require('../assets/fonts/RobotoCondensed-Regular.ttf'),

    'DMSans-Regular': require('../assets/fonts/DMSans-Regular.ttf'),
    'DMSans-Italic': require('../assets/fonts/DMSans-Italic.ttf'),
    'DMSans-Medium': require('../assets/fonts/DMSans-Medium.ttf'),
    'DMSans-MediumItalic': require('../assets/fonts/DMSans-MediumItalic.ttf'),
    'DMSans-Bold': require('../assets/fonts/DMSans-Bold.ttf'),
    'DMSans-BoldItalic': require('../assets/fonts/DMSans-BoldItalic.ttf')});

  const [modalVisible, setModalVisible] = useState(true);
  const [sortAndFilterVisible, setSortAndFilterVisible] = useState(false);

  const inset = useSafeAreaInsets();
  const {currentPlatform, isAndroid, isIos, isWeb, isDesktopWeb} = usePlatform();
  const dimensions = useDimensions({isWeb, isDesktopWeb});
  const currentScreen = useCurrentScreen();
  const headerHeight = 64;
  const contentHeight = dimensions.height - headerHeight - (isIos ? inset.top : 0);

  const defaultCurrentGradient = [tw`text-primary-dark`.color, tw`text-primary`.color];
  const [currentGradient, setCurrentGradient] = useState(defaultCurrentGradient);

  const [menuOpen, setMenuOpen] = useState(false);
  const [afterAgePath, setAfterAgePath] = useState('/');
  const [scrollTop, setScrollTop] = useState(true);
  const [offerImageContainerWidth, setOfferImageContainerWidth] = useState(100);
  const offersScrollPositionRef = useRef(0);
  const [errors, setErrors] = useState([]);

  const [statusMessage, setStatusMessage] = useState({visible: false, type: 'info', message: '', details: ''});
  const [statusMessageTimeout, setStatusMessageTimeout] = useState();
  const statusMessageRef = useRef(statusMessage);

  const [searchQuery, setSearchQuery] = useState('');
  const [typeFilter, setTypeFilter] = useState('all');
  const [sortBy, setSortBy] = useState('new');

  useEffect(() => {
    statusMessageRef.current = statusMessage;
  }, [statusMessage]);

  const routerLocation = routerUseLocation();
  const previousRouterLocation = usePrevious(routerLocation);

  const {analyticsPageView,
    analyticsEvent} = useFirebaseAnalytics({
    ga4MeasurementId,
    routerLocation,
    pathToPageName})
  const {openedWithUrl, openedWithParams, openedWithUrlReady} = useOpenedWithUrl(navigate)

  useEffect(() => setMenuOpen(false), [routerLocation]);

  const {activeState, isActive, toForeground, toBackground} = useAppState();
  const store = useStore(config.rememberMeExpiresMilliseconds, log, debug);
  const {auth, authReady, authError,
    getUser,
    login, logout,
    signup, updateAccount,
    submitPhoneNumber,
    verifyPhoneNumber} = useTokenAuth({config, toForeground, store, navigate, displayStatusMessage});

  const {location, locationReady, locationError} = useGeolocation();
  const {selectedState, setSelectedState, previousSelectedState,
    getSelectedStateFromStore, selectedStateFromStoreReady,
    afterLocationPath} = useSelectedState({store, locationError, previousRouterLocation})

  // const {swipableViewProps} = isWeb ? {swipableViewProps : {}} : useSwipe({navigate, dimensions, menuOpen, setMenuOpen});
  const {swipableViewProps} = {swipableViewProps : {}};

  const {offers, offersLoading, offersReady, intinalOffersLoading, getOffers} = useOffers({config, store, toForeground, usStates, navigate,
    location, locationReady, locationError,
    selectedState, setSelectedState, previousSelectedState,
    getSelectedStateFromStore, selectedStateFromStoreReady,
    errors, setErrors});

  const {consumerBalances, consumerBalancesLoading, consumerBalancesReady, getConsumerBalances} = useConsumerBalances(config, toForeground, auth, errors, setErrors);
  const {mediaUploads, mediaUploadsLoading, mediaUploadsReady, getMediaUploads} = useMediaUploads(config, toForeground, auth, errors, setErrors);
  const {age, ageLoading, ageReady, handleAtLeast21, handleNot21} = useAgeGate(store, locationReady);
  const {faq, faqLoading, faqReady, getFaq} = useFaq(config, toForeground, auth, errors, setErrors);

  useEffect(() => {
    if (!locationReady || selectedStateFromStoreReady) return
    const previousPath = previousRouterLocation.pathname || '/';
    if (locationReady && !location) setAfterAgePath('/location');
    else if (locationReady && location) setAfterAgePath(previousPath);
  }, [location, locationReady])

  useEffect(() => {
    if (ageReady && !age) {
      const previousPath = previousRouterLocation.pathname || '/';
      setAfterAgePath(previousPath);
      navigate('/age');}
  }, [age, ageReady])

  // TODO: Comment this out
  let log = console.log
  if (debug) log = useDebugLogger({
    auth,
    authReady,
    authError,
    location,
    locationReady,
    locationError,
    selectedState,
    previousSelectedState,
    selectedStateFromStoreReady,
    age,
    ageReady,
    offersReady,
    errors})

  function displayStatusMessage(message=false) {
    if (message) {
      setStatusMessage({...message, visible: true});

      const timeout = setTimeout(() => {
        setStatusMessage({...statusMessageRef.current, visible: false});
        setStatusMessageTimeout();
      }, 5000);

      clearTimeout(statusMessageTimeout);
      setStatusMessageTimeout(timeout);
    } else {
      setStatusMessage({...statusMessage, visible: false});
      clearTimeout(statusMessageTimeout);
      setStatusMessageTimeout();}}

  function handleScroll(event) {
    if (event.nativeEvent.contentOffset.y > 0) setScrollTop(false);
    else setScrollTop(true);}

  const contextValue = {debug, log, config, tw, usStates, store,
    modalVisible, setModalVisible,
    sortAndFilterVisible, setSortAndFilterVisible,
    analyticsPageView, analyticsEvent,
    activeState, toForeground, toBackground,
    age, ageLoading, ageReady, handleAtLeast21, handleNot21, afterAgePath,
    fontsLoaded, swipableViewProps,
    currentPlatform, isAndroid, isIos, isWeb, isDesktopWeb,
    inset, dimensions, headerHeight, contentHeight, currentScreen,
    auth, login, logout, signup, submitPhoneNumber, verifyPhoneNumber, updateAccount,
    navigate, routerLocation,
    openedWithUrl, openedWithParams, openedWithUrlReady,
    selectedState, setSelectedState,
    location, locationError, locationReady, afterLocationPath,
    menuOpen, setMenuOpen,
    statusMessage, displayStatusMessage,
    searchQuery, setSearchQuery, typeFilter, setTypeFilter, sortBy, setSortBy,
    defaultCurrentGradient, currentGradient, setCurrentGradient,
    scrollTop, setScrollTop, handleScroll,
    offerImageContainerWidth, setOfferImageContainerWidth,
    // offersScrollPosition, setOffersScrollPosition,
    offersScrollPositionRef,
    offers, offersLoading, offersReady, getOffers, intinalOffersLoading,
    faq, faqLoading, faqReady, getFaq,
    consumerBalances, consumerBalancesLoading, consumerBalancesReady, getConsumerBalances,
    mediaUploads, mediaUploadsLoading, mediaUploadsReady, getMediaUploads};

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}

export default Context;
