import {useContext} from "react";
import {Svg, Path} from 'react-native-svg';

import Context from "../context.jsx";

export default function SearchIcon({size, color, backgroundColor}) {
  const {tw} = useContext(Context);

  return <Svg xmlns="http://www.w3.org/2000/svg" 
    style={tw`w-${size} h-${size}`}
    fill="none"
    viewBox="0 0 24 24" 
    strokeWidth={2.14} 
    stroke={color} >
  <Path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
</Svg>
}

