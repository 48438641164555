import {useState, useEffect} from "react";

import usePrevious from "./usePrevious.jsx";


export default function useSelectedState({store, locationError, previousRouterLocation}) {
  const [selectedState, setSelectedState] = useState(null);
  const [selectedStateFromStoreReady, setSelectedStateFromStoreReady] = useState(false);
  const [afterLocationPath, setAfterLocationPath] = useState('/');
  const [selectedStateError, setSelectedStateError] = useState(false);

  const previousSelectedState = usePrevious(selectedState);

  useEffect(() => {
    if (locationError && !selectedStateFromStoreReady) {
      getSelectedStateFromStore()}
  }, [locationError,
    selectedStateFromStoreReady]);

  useEffect(() => {
    if (selectedState && selectedStateFromStoreReady &&
      selectedState !== previousSelectedState) store.set('selectedState', selectedState);
  }, [selectedState, selectedStateFromStoreReady, previousSelectedState]);

  useEffect(() => {
    if (selectedStateFromStoreReady && !selectedState) {
      const previousPath = previousRouterLocation?.pathname || '/';
      setAfterLocationPath(['/age', '/location'].includes(previousPath) ? '/' : previousPath);}
  }, [selectedState, selectedStateFromStoreReady, previousRouterLocation]);

  function getSelectedStateFromStore() {
    store.get('selectedState')
      .then(function({data, error}) {
        if (error) {
          setSelectedStateError(error)
          setSelectedStateFromStoreReady(true)}
        setSelectedState(data)
        setSelectedStateFromStoreReady(true)})
  }

  return {selectedState, setSelectedState, previousSelectedState, getSelectedStateFromStore,
    selectedStateFromStoreReady, afterLocationPath};
}
