import {useState, useContext} from "react";
import {View, Text, Pressable, Image} from "react-native";

import {formatUtcDate, formatMoneyCondensed} from "js-utilities";

import ChevronUpIcon from './icons/chevronUpIcon.jsx';
import ChevronDownIcon from './icons/chevronDownIcon.jsx';
import Context from "./context.jsx";


export default function MediaUpload({type, mediaUpload, initiallyExpanded, lastItem}){
  const {tw, currentGradient} = useContext(Context);

  const [expanded, setExpanded] = useState(initiallyExpanded);

  const formatMediaUploadStatus = status => {
  switch(status) {
    case 'submitted':
      return 'submitted';
    case 'in_review':
      return 'in review';
    case 'accepted':
      return 'accepted';
    case 'rejected':
      return 'rejected';

    case 'failed_disbursement_method':
      return 'failed';
    case 'failed_no_redemptions':
      return 'failed';
    case 'failed_unsafe_to_redeem':
      return 'failed';
    case 'failed':
      return 'failed';

    default:
      return status;}}

  return <View style={tw`bg-white my-xs rounded-md bg-white border border-light shadow`}>
    <Pressable onPress={() => setExpanded(!expanded)}
      style={tw`flex-row items-center p-xs`}>

      <Text style={tw`mr-sm text-left text-lg ${type === 'rejected' ? 'text-ruby' : 'text-green-accent'} font-primaryBold`}>
        {formatMoneyCondensed(mediaUpload.offer?.discount_cents)}
      </Text>

      <Text style={tw`text-left flex-1 font-primaryMedium`}>
        {mediaUpload.offer?.name}
      </Text>

      <View style={tw`ml-auto flex-row justify-end items-center`}>
        {expanded ? <ChevronUpIcon color={tw`text-[${currentGradient[1]}]`.color} size={5} />
        : <ChevronDownIcon color={tw`text-[${currentGradient[1]}]`.color} size={5} /> }
      </View>
    </Pressable>

    {expanded ? <>
      <View style={tw`flex-row flex-wrap justify-around items-center`}>
        <Text style={tw`mt-xs text-center`}>
          <Text style={tw`font-primaryMedium`}>Date:</Text> {formatUtcDate(new Date(mediaUpload.created_at.slice(0,10)))}
        </Text>

        <Text style={tw`mt-xs text-center`}>
          <Text style={tw`font-primaryMedium`}>Status:</Text> {formatMediaUploadStatus(mediaUpload.status)}
        </Text>

        {mediaUpload.denial_reason ? <Text style={tw`mt-xs text-center`}>
          <Text style={tw`font-primaryMedium`}>Reason:</Text>
          <Text style={tw`text-ruby`}> {mediaUpload.denial_reason}</Text>
        </Text> : null}
      </View>
    
      {mediaUpload.media_upload_images ? <View style={tw`flex-row flex-wrap justify-center w-full`}>
        {mediaUpload.media_upload_images.map((mui, index) => <View key={`mediaUploadImage-${index}`} style={tw`w-1/2 max-w-64 aspect-square p-xs`}>
          <Image style={tw`w-full h-full rounded-sm bg-white shadow-sm`}
            source={{uri: mui.image_url}} />
        </View>)}
      </View> : null}
    </> : null}
  </View>;
}
