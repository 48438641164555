import React from 'react';
import {Platform} from "react-native";
import {
  NativeRouter,
  Routes as NativeRoutes,
  Route as NativeRoute,
  Navigate as NativeNavigate,
  Link as NativeLink,
  useNavigate as NativeUseNavigate,
  useLocation as NativeUseLocation,
  useParams as nativeUseParams,
  redirect as nativeRedirect,
} from "react-router-native";
import {
  BrowserRouter,
  Routes as BrowserRoutes,
  Route as BrowserRoute,
  Navigate as BrowserNavigate,
  Link as BrowserLink,
  useNavigate as BrowserUseNavigate,
  useLocation as BrowserUseLocation,
  useParams as browserUseParams,
  redirect as browserRedirect,
} from "react-router-dom";


const mobilePlatform = ['android', 'ios'].includes(Platform.OS);

const Router = mobilePlatform ? NativeRouter : BrowserRouter;
const Routes = mobilePlatform ? NativeRoutes : BrowserRoutes;
const Route = mobilePlatform ? NativeRoute : BrowserRoute;
const Navigate = mobilePlatform ? NativeNavigate : BrowserNavigate;
const Link = mobilePlatform ? function(props) {
  return <NativeLink {...props} underlayColor='none' />;
} : BrowserLink;
const useNavigate = mobilePlatform ? NativeUseNavigate : BrowserUseNavigate;
const useLocation = mobilePlatform ? NativeUseLocation : BrowserUseLocation;
const useParams = mobilePlatform ? nativeUseParams : browserUseParams;
const redirect = mobilePlatform ? nativeRedirect : browserRedirect;

function NavigateWithParams({ to, ...rest }) {
  const params = useParams();
  let toValue;
  if (typeof to === 'function') {
    toValue = to(params);
  } else {
    toValue = to;
  }
  return <Navigate to={toValue} {...rest} />
}

export {
  Router, Routes, Route, Navigate, Link, useNavigate, useLocation, useParams,
  redirect, NavigateWithParams, mobilePlatform,
};
