import {View, Image, Pressable, Text} from "react-native";
import {useState, useEffect, useContext, useLayoutEffect} from "react";
import {animated, useSpring, useSpringValue} from "./utilities/wrapSpring";
import { useLocation } from "./utilities/router.jsx";
import {Svg, Path} from 'react-native-svg';

import {pSBC, formatMoney} from "js-utilities";

import Context from "./context.jsx";
import {LinearGradient} from 'expo-linear-gradient';
import {BlurView} from "../assets/functions/objects";

// import Logo from "../assets/images/bybeLogoPrimaryWhite.png";
import Logo from "../assets/images/bybeOriginalLogoWhiteMedium.png";
import MenuIcon from "./icons/menuIcon.jsx";
import SearchIcon from "./icons/searchIcon.jsx";
// export default function Header({menuOpen, setMenuOpen}) {
//   const {tw, headerHeight, selectedState, setSelectedState, storeGet, navigate} = useContext(Context);

//   return <View style={tw`flex flex-row justify-center justify-between items-center z-30 h-[${headerHeight}px] w-full px-4 bg-primary-dark shadow-md`}>
//     <Pressable onPress={() => navigate('/')}>
//       <Image style={tw`h-24 w-24`}
//         source={Logo}
//         resizeMethod='resize'
//         resizeMode='contain' />
//     </Pressable>

//     {/* TODO: Don't use menu on web. State in middle. Login/signup on left */}
//     <Pressable onPress={() => setMenuOpen(!menuOpen)}>
//       <MenuIcon size={10} color="white" />
//     </Pressable>
//   </View>;
// }

export default function Header({menuOpen, setMenuOpen}) {
  const {tw, inset, headerHeight, navigate, isAndroid, isIos, currentGradient, scrollTop, isDesktopWeb, selectedState, consumerBalances, consumerBalancesReady, sortAndFilterVisible, setSortAndFilterVisible} = useContext(Context);

  const [pendingConsumerBalance, setPendingConsumerBalance] = useState(false);

  const AnimatedBlurView = animated(BlurView);
  const AnimatedLinearGradient = animated(LinearGradient);
  const location = useLocation();

  const [animatedBlurViewSprings, animatedBlurViewApi] = useSpring(() => ({
    from: {opacity: 0},
    config: {duration: 333}
  }));

  const [animatedLinearGradientSprings, animatedLinearGradientApi] = useSpring(() => ({
    from: {opacity: 0.24},
    config: {duration: 333}
  }));

  const gradientEnd = useSpringValue([0, 1], {config: {duration: 200}});

  useEffect(() => {
    if (consumerBalancesReady && consumerBalances.length) {
      const tempPendingConsumerBalance = consumerBalances.find(consumerBalance => consumerBalance.status === 'pending')
      if (tempPendingConsumerBalance) setPendingConsumerBalance(tempPendingConsumerBalance)
      else setPendingConsumerBalance(false);}
    else setPendingConsumerBalance(false);
  }, [consumerBalances, consumerBalancesReady]);

  useLayoutEffect(() => {
    const linearGradientOpacity = menuOpen ? 0.64 : scrollTop ? 0.24 : 0.64;
    const blurViewOpacity = menuOpen ? 1 : scrollTop ? 0 : 1;

    animatedBlurViewApi.start({to: {opacity: blurViewOpacity}});
    animatedLinearGradientApi.start({to: {opacity: linearGradientOpacity}});
    gradientEnd.start(menuOpen ? [0, 0.56] : [0, 1]);
  }, [menuOpen, scrollTop]);

  const androidShadowTransform = isAndroid ? [{scaleX: 1.011}, {scaleY: 1.083}] : [];
  // const androidShadowSmTransform = isAndroid ? [{scaleX: 1.005}, {scaleY: 1.03}] : [];

  return <View style={tw`absolute h-[${headerHeight + (isIos ? inset.top : 0)}px] w-full z-30 overflow-hidden`}>
    <View style={tw.style(`absolute w-full h-full bg-dark-dark bg-opacity-0 ${scrollTop ? '' : 'shadow'}`, {transform: androidShadowTransform})}
      needsOffscreenAlphaCompositing={true}
      renderToHardwareTextureAndroid={true} />

    <AnimatedBlurView style={tw.style(`absolute w-full h-full`, animatedBlurViewSprings)}
      blurAmount={24}
      blurType={'light'}
      needsOffscreenAlphaCompositing={true} />

    <AnimatedLinearGradient colors={[currentGradient[0], pSBC(-0.33, currentGradient[1])]}
      style={tw.style(`absolute w-full h-full`, animatedLinearGradientSprings)}
      start={{x: 0.1, y: 0.9}}
      end={{x: 0.9, y: 0.1}}
      locations={gradientEnd} />

    <View style={tw`flex flex-row justify-center justify-between items-center w-full h-full px-base pt-[${isIos ? inset.top : 0}px]`}>
      <Pressable onPress={() => navigate('/')}>
        <Image style={tw`h-24 w-24`}
          source={Logo}
          resizeMethod='resize'
          resizeMode='contain' />
      </Pressable>

      {selectedState && isDesktopWeb ? <Pressable onPress={() => navigate('/location')} style={tw`py-1.5sm`}>
        <Text style={tw`text-4xl text-center text-white font-primaryMedium font-spacing-sm`}>{selectedState.name}</Text>
      </Pressable> : null}

      <View style={tw`flex-row w-24 justify-end items-center`}>
        {pendingConsumerBalance ? <Pressable onPress={() => navigate('/balance')} style={tw.style(`flex flex-row mr-base`, {
            verticalAlign: 'middle'
          })}>
          <Text style={tw`text-2xl text-white font-primaryBold font-spacing-sm leading-2`}>$</Text>
          <Text style={tw`text-2xl text-white font-primaryBold font-spacing-sm leading-2`}>{(pendingConsumerBalance.balance_cents / 100).toFixed(2)}</Text>
        </Pressable> : null}

        {location.pathname === '/' ? <Pressable style={tw`w-6 mr-base`}
          onPress={() => setSortAndFilterVisible(!sortAndFilterVisible)}>
          <SearchIcon size="7" color="white" />
        </Pressable> : null}

        <Pressable style={tw`items-center`} onPress={() => setMenuOpen(!menuOpen)}>
          <MenuIcon size={10} color="white" />
        </Pressable>
      </View>
    </View>
  </View>;

}

// function FilterIcon(){
//   return <Svg id='Layer_1' xmlns='http://www.w3.org/2000/svg' className="w-5 h-5" viewBox='0 0 100 94.56' fill="white" stroke="white">
//     <Path className='cls-1' d='m100,7.81c-.45,3.02-2.68,4.89-4.56,6.97-9.88,10.89-19.78,21.76-29.69,32.62-.52.57-.75,1.14-.75,1.91.02,8.95-.01,17.9.03,26.85.01,3.03-1.17,5.29-3.72,6.95-5.04,3.29-10.03,6.65-15.03,10.01-2.42,1.63-4.95,1.96-7.55.57-2.6-1.39-3.74-3.7-3.74-6.59,0-12.53-.01-25.06.01-37.59,0-.91-.27-1.58-.88-2.26C23.62,35.74,13.16,24.19,2.64,12.69c-1.31-1.43-2.24-3-2.64-4.88v-1.17c.1-.4.2-.8.3-1.2C1.21,2.07,3.84.16,7.58.16c28.28,0,56.56,0,84.84,0,.29,0,.59,0,.88.02,2.94.15,5.43,2.08,6.29,4.88.16.52.28,1.05.42,1.58v1.17Zm-49.9-1.67c-14.03,0-28.05,0-42.08,0-.39,0-.79-.02-1.17.06-.7.15-.98.81-.63,1.42.16.28.4.51.61.75,10.59,11.65,21.18,23.3,31.8,34.93,1.6,1.76,2.38,3.7,2.37,6.08-.04,12.43-.03,24.86,0,37.3,0,.58.2,1.38.6,1.67.29.21,1.15-.13,1.62-.44,4.89-3.22,9.75-6.49,14.65-9.71.84-.55,1.14-1.17,1.14-2.16-.03-8.98,0-17.96-.03-26.95,0-2.13.67-3.92,2.11-5.49,8.46-9.28,16.9-18.57,25.35-27.86,2.32-2.55,4.66-5.09,6.94-7.67.29-.32.48-.97.35-1.34-.1-.3-.75-.45-1.18-.58-.27-.08-.58-.02-.87-.02-13.86,0-27.73,0-41.59,0Z'/>
//     <Path className='cls-1' d='m100,6.64c-.14-.53-.26-1.06-.42-1.58-.86-2.8-3.35-4.73-6.29-4.88-.29-.01-.59-.02-.88-.02-28.28,0-56.56,0-84.84,0-3.74,0-6.37,1.91-7.28,5.27-.11.4-.2.8-.3,1.2V0c33.33,0,66.67,0,100,0v6.64Z'/>
//   </Svg>

// }

// Transparnet Header SVG Code for reference
{/* <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="439" height="99" viewBox="0 0 439 99">
  <defs>
    <linearGradient id="linear-gradient" y1="1" x2="1" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#2c1c66"/>
      <stop offset="1" stop-color="#6438ff"/>
    </linearGradient>
    <filter id="headerBg2" x="0" y="0" width="439" height="99" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="4.5" result="blur"/>
      <feFlood flood-opacity="0.2"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
  </defs>
  <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#headerBg2)" style="mix-blend-mode: darken;isolation: isolate">
    <rect id="headerBg2-2" data-name="headerBg2" width="412" height="72" transform="translate(13.5 10.5)" opacity="0.12" fill="url(#linear-gradient)"/>
  </g>
</svg> */}

// Normal Header SVG Code for reference
{/* <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="439" height="99" viewBox="0 0 439 99">
  <defs>
    <linearGradient id="linear-gradient" y1="1" x2="1" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#2c1c66"/>
      <stop offset="0.067" stop-color="#6438ff"/>
      <stop offset="1" stop-color="#6438ff"/>
    </linearGradient>
    <filter id="headerBg2" x="0" y="0" width="439" height="99" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="4.5" result="blur"/>
      <feFlood flood-opacity="0.2"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
  </defs>
  <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#headerBg2)" style="mix-blend-mode: darken;isolation: isolate">
    <rect id="headerBg2-2" data-name="headerBg2" width="412" height="72" transform="translate(13.5 10.5)" opacity="0.56" fill="url(#linear-gradient)"/>
  </g>
</svg> */}
