import {useContext} from "react";
import {Svg, Path} from 'react-native-svg';

import Context from "../context.jsx";


export default function XIcon({size, strokeWidth = 1.5, color}) {
  const {tw} = useContext(Context);
  
  return <Svg xmlns="http://www.w3.org/2000/svg"style={tw`w-${size} h-${size}`}
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={strokeWidth}
    stroke={color}>
    <Path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
  </Svg>
}
