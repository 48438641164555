import {useState, useEffect} from 'react';

import {ajaxRequest} from "js-utilities";

import usePrevious from './usePrevious';


export default function useMediaUploads(config, toForeground, auth, errors, setErrors) {
  const [mediaUploads, setMediaUploads] = useState([]);
  const [mediaUploadsLoading, setMediaUploadsLoading] = useState(false);
  const [mediaUploadsReady, setMediaUploadsReady] = useState(false);

  const previousAuth = usePrevious(auth);
  
  useEffect(() => {
    if (auth) getMediaUploads()
  }, [toForeground, auth])

  useEffect(() => {
    if (previousAuth && !auth) setMediaUploads([])
  }, [auth, previousAuth])

  function getMediaUploads() {
    if (!auth) return;
    setMediaUploadsLoading(true);

    ajaxRequest("GET", `${config.apiEndpoint}/media_uploads`, {
      params: {status: 'submitted,rejected'},
      json: true,
      headers: auth.authHeaders
    }).then(response => {
      setMediaUploads(response.body.media_uploads);
      setMediaUploadsLoading(false);
      setMediaUploadsReady(true);
    }).catch(response => {
      setErrors([...errors, response.body]);
      setMediaUploadsLoading(false);
      setMediaUploadsReady(true);});}

  return {mediaUploads, mediaUploadsLoading, mediaUploadsReady, getMediaUploads};
}
