import {useContext, useState} from "react";
import {View, Text, TextInput, Switch, Pressable} from "react-native";

import {emailValid} from 'js-utilities';

import Page from "./ui/page.jsx";
import Button from './ui/button.jsx';
import LoadingSpinner from "./ui/loadingSpinner.jsx";

import Context from './context.jsx';


export default function Login() {
  const {tw, login, navigate, currentGradient, displayStatusMessage} = useContext(Context);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);

  function validateInput(input, type) {
    switch (type) {
      case 'email':
        if (emailValid(input)) return true;
        else return displayStatusMessage({type: 'error', message: 'Invalid Email', details: 'Please enter a valid email address'});
      case 'password':
        if (input.length >= 8) return true;
        else return displayStatusMessage({type: 'error', message: 'Invalid Password', details: 'Password must be at least 8 characters'});
      default:
        setLoading(false);
        return displayStatusMessage({type: 'error', message: 'Error', details: 'Input is invalid'});}}

  function handleSubmit() {
    setLoading(true);
    if (!validateInput(email, 'email')) {
      setLoading(false);
      return;}
    if (!validateInput(password, 'password')){
      setLoading(false);
      return;}

    login(email, password, rememberMe).then(({newAuth, error})=>{
      if(error) return setLoading(false);
      setLoading(false);
      displayStatusMessage({type: 'success', message: 'Logged In', details: 'Welcome back!'});
      navigate('/');
    });}

  // TODO: Submit on enter press

  return <Page header='Login' bottomContent={<View style={tw`flex-row`}>
      <Pressable onPress={() => navigate('/reset-password')}>
        <Text style={tw`text-white font-primaryMedium font-spacing-sm`}>Reset Password</Text>
      </Pressable>

      <Text style={tw`text-white font-primaryMedium font-spacing-sm`}> | </Text>

      <Pressable onPress={() => navigate('/signup')}>
        <Text style={tw`text-white font-primaryMedium font-spacing-sm`}>Create BYBE Account</Text>
      </Pressable>
    </View>}>
    {loading ? <LoadingSpinner /> : <View style={tw`w-full`}>
      <Text style={tw`mb-xs text-lg text-dark-dark font-primaryMedium font-spacing`}>Email</Text> 
      <TextInput style={tw`mb-sm p-xs rounded-sm border border-dark text-lg font-primaryMedium font-spacing outline-none`}
        type='email'
        autoCompleteType="email"
        textContentType="emailAddress"
        onChangeText={setEmail}
          value={email} />

      <Text style={tw`mb-xs text-lg text-dark-dark font-primaryMedium font-spacing`}>Password</Text>
      <TextInput style={tw`mb-sm p-xs rounded-sm border border-dark text-lg font-primaryMedium font-spacing outline-none`}
        onChangeText={setPassword}
        value={password}
        textContentType='password'
        secureTextEntry />

      <View style={tw`flex flex-row items-center mb-sm`}>
        <Switch style={tw`mr-xs`}
          value={rememberMe}
          onValueChange={() => setRememberMe(!rememberMe)}
          trackColor={{false: tw`text-dark-light`.color, true: currentGradient[1]}}
          thumbColor={currentGradient[0]}
          ios_backgroundColor={{false: tw`text-dark-light`.color, true: currentGradient[1]}} />

        <Text sylte={tw`text-lg text-dark-dark font-primaryMedium font-spacing`}>Remember Me</Text>
      </View>

       <Button onPress={handleSubmit}
        color={currentGradient[0]}
        text='Login' />
    </View>}
  </Page>
}
