import {useRef} from 'react';
import Storage from 'react-native-storage';
import AsyncStorage from '@react-native-async-storage/async-storage';

import usePlatform from './usePlatform.jsx';

const defaultExpires = 1000 * 60 * 60 * 24 * 7; // 7 days

export default function useStore(expires=defaultExpires, log=console, debug=false) {
  const {isWeb, isDesktopWeb} = usePlatform()

  const store = useRef(new Storage({
    size: 1000,
    storageBackend: isWeb || isDesktopWeb
      ? window.localStorage
      : AsyncStorage,
    defaultExpires: expires,
    enableCache: true})).current;

  function fetchCache(key, computeValue) {
    return new Promise(function(resolve) {
      get(key)
        .then(function({data}) {
          if (debug) log.info('store fetch', key, data)
          if (data) resolve({data})
          else computeAndSet()})
        .catch(function({error}) {
          if (debug) log.error('store fetch error', key, error)})

      function computeAndSet() {
        return computeValue()
          .then(function({value, error}) {
            if (error) {
              if (debug) log.error('store - fetch compute error', key, error)
              return resolve({error})}
            set(key, value)
              .then(function({data}) {
                if (debug) log.info('store fetch - set computed value success', key, data)
                resolve({data})})
              .catch(function({error}) {
                if (debug) log.error('store fetch - set computed value error', key, error)
                resolve({data, error})})})
          .catch(function({error}) {
            resolve(error)})
      }
    })
  }

  function get(key) {
    return new Promise(function(resolve) {
      store.load({key})
        .then(function(data) {
          if (debug) log.info('store get - success', key, data)
          resolve({data})})
        .catch(function(error) {
          const isNotFoundError = error.name === 'NotFoundError'
          const details = isNotFoundError
              ? 'store get - not found'
              : 'store get - error'
          if (debug) {
            if (isNotFoundError) log.info(details, key, error)
            else log.error(details, key, error)
          }
          resolve({error})
        })
    })  
  }

  function set(key, value) {
    return new Promise(function(resolve) {
      store.save({key, data: value})
        .then(function() {
          if (debug) log.info('store set - success', key, value);
          resolve({data: value})})
        .catch(function(error) {
          if (debug) log.error('store set - error', key, value, error);
          resolve({error})})
    })
  }

  function remove(key) {
    return new Promise(function(resolve) {
    store.remove({key})
      .then(function() {
        if (debug) log.info('store remove - success', key)
        resolve()
      })
      .catch(function(error) {
        if (debug) log.error('store remove - error', key, error)
        resolve({error})
      })
    })
  }

  return {fetchCache, get, set, remove}
}
