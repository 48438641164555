import {useContext} from 'react';
import {View} from 'react-native';
import {animated} from "../utilities/wrapSpring";
import {LinearGradient} from 'expo-linear-gradient';

import {pSBC} from "js-utilities";

import {BlurView} from "../../assets/functions/objects";

import Context from '../context';


export default function AnimatedBlurPanel({children, style, blurViewOpacity, linearGradientOpacity, gradientLocations, gradient, roundedFull, opacityOnChildren, hidden, showOverflow}) {
  const {tw, isAndroid, currentGradient} = useContext(Context);

  const AnimatedView = animated(View);
  const AnimatedBlurView = animated(BlurView);
  const AnimatedLinearGradient = animated(LinearGradient);

  const effectiveGradient = gradient || [currentGradient[0], pSBC(-0.33, currentGradient[1])];

  const androidShadowTransform = isAndroid ? [{scaleX: 1.011}, {scaleY: 1.083}] : [];

  return <View style={tw.style(`${roundedFull ? 'rounded-full' : ''} ${hidden ? 'hidden' : ''}`, {...style, overflow: showOverflow ? 'visible' : 'hidden'})}>
    <AnimatedView style={tw.style(`absolute w-full h-full ${roundedFull ? 'rounded-full' : ''} shadow`, {transform: androidShadowTransform, opacity: blurViewOpacity})}
      needsOffscreenAlphaCompositing={true}
      renderToHardwareTextureAndroid={true} />

    <AnimatedBlurView style={tw.style(`absolute w-full h-full ${roundedFull ? 'rounded-full' : ''}`, {opacity: blurViewOpacity, overflow: `${isAndroid ? 'hidden' : 'visible'}`})}
      blurAmount={24}
      overlayColor='transparent'
      blurType='light'
      needsOffscreenAlphaCompositing={true} />

    <AnimatedLinearGradient colors={effectiveGradient}
      style={tw.style(`absolute w-full h-full ${roundedFull ? 'rounded-full' : ''}`, {opacity: linearGradientOpacity, overflow: 'hidden'})}
      start={{x: 0.1, y: 0.9}}
      end={{x: 0.9, y: 0.1}}
      locations={gradientLocations} />

    {opacityOnChildren ? <AnimatedView style={tw.style(`w-full h-full`, {opacity: blurViewOpacity})}>
      {children}
    </AnimatedView> : children}
  </View>;
}
