import {Pressable} from "react-native";
import {useEffect, useContext} from "react";
import {animated, useSpring} from "../utilities/wrapSpring";

import Context from "../context.jsx";
import {BlurView} from "../../assets/functions/objects";


export default function UiBlurView({onPress}) {
  const {tw} = useContext(Context);
  const AnimatedBlurView = animated(BlurView);

  const [animatedBlurViewSprings, animatedBlurViewApi] = useSpring(() => ({
    from: {opacity: 0},
    config: {duration: 333}
  }));

  useEffect(() => {
    animatedBlurViewApi.start({to: {opacity: 1}});
    return () => animatedBlurViewApi.start({to: {opacity: 0}});
  }, []);

  return <>
    <AnimatedBlurView style={tw.style(`absolute w-full h-full`, animatedBlurViewSprings)}
      blurAmount={4}
      blurType='dark' />
    <Pressable style={tw.style(`absolute w-full h-full`)} onPress={onPress} />
  </>;
}
