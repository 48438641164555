const plugin = require('tailwindcss/plugin');

module.exports = {
  content: [
    './src/**/*.{js,jsx,ts,tsx,vue}'
  ],
  theme: {
    // Spacing
    spacing: {
      '0.25xs': '0.140625rem',
      '0.5xs': '0.28125rem',
      '0.75xs': '0.421875rem',
      'xs': '0.5625rem',
      '1.5xs': '0.703125rem',
      'sm': '0.84375rem',
      '1.5sm': '0.984375rem',
      'base': '1.125rem',
      '1.5base': '1.45625rem',
      'lg': '1.7375rem',
      '1.5lg': '2.01875rem',
      'xl': '2.25rem',
      '1.25xl': '2.53125rem',
      '1.5xl': '2.8125rem',
      '1.75xl': '3.09375rem',
      '2xl': '3.375rem',
      '2.5xl': '3.9375rem',
      '3xl': '4.5rem',
      '3.5xl': '5.0625rem',
      '4xl': '5.625rem',
      '4.5xl': '6.1875rem',
      '5xl': '6.75rem',
      '5.5xl': '7.875rem',
      '6xl': '9rem',
    },
    screens: {
      'xs': '360px',
      'sm': '480px',
      'md': '720px',
      'lg': '1080px',
      'xl': '1440px'
    },
    fontFamily: {
      'primary': ['DMSans-Regular', 'sans-serif'],
      'primaryMedium': ['DMSans-Medium', 'sans-serif'],
      'primaryBold': ['DMSans-Bold', 'sans-serif'],
      'primaryItalic': ['DMSans-Italic', 'sans-serif'],
      'secondary': ['RobotoCondensed-Regular', 'sans-serif'],
      'secondaryBold': ['RobotoCondensed-Bold', 'sans-serif']
    },
    extend: {
      // Misc
      inset: {
        '1/5': '20%',
        '2/5': '40%',
        'negative-6px': '-6px'
      },
      fontSize: {
        // extra small
        '0.5sm': '0.9375rem',
        'sm': '0.875rem',
        '1.5sm': '0.8125rem',
        'xs': '0.75rem',
        '1.5xs': '0.6875rem',
        '2xs': '.625rem',
        '3xs': '.5625rem',
        '4xs': '.5rem',

        // extra large
        // 'xl': '1.25rem',
        // '2xl': '1.375rem',
        // '3xl': '1.5rem',
        // '3.5xl': '1.5625rem',
        // '4xl': '1.625rem',
        // '5xl': '1.75rem',
        // '6xl': '2rem',
        // '7xl': '2.5rem',
        // '8xl': '3.5rem',
        // '9xl': '5rem'

        // extra large (finer control)
        'lg': '1.0625rem',
        'xl': '1.125rem',
        '1.5xl': '1.1875rem',
        '2xl': '1.25rem',
        '2.5xl': '1.3125rem',
        '3xl': '1.375rem',
        '3.5xl': '1.4375rem',
        '4xl': '1.5rem',
        '4.5xl': '1.5625rem',
        '5xl': '1.625rem',
        '5.5xl': '1.6875rem',
        '6xl': '1.75rem',
        '6.5xl': '1.875rem',
        '7xl': '2rem',
        '8xl': '2.5rem',
        '8.5xl': '3.25rem',
        '9xl': '4rem'
      },
      colors: {
        // color palette
        primary: {
          DEFAULT: '#6438ff',
          dark: '#2c1c66'
        },
        secondary: {
          DEFAULT: '#ece159',
          dark: '#e47332',
          original: '#f05138',
          'original-dark': '#bd3229',
        },
        accent: {
          DEFAULT: '#92e0ff',
          light: '#a8ffde',
          dark: '#2B6EFF',
          dark2: '#1EB324',
          dark3: '#288699',
          accent: '#FF6E52'
        },
        // new colors to experiment with
        white: {
          DEFAULT: '#FFFFFF',
          alt: '#FEFCFB',
          alt2: '#F2F4F6',
          alt3: '#EFF1F3',
          accent: '#F0FFF1',
          accent2: '#E2E8DD'
        },
        black: {
          DEFAULT: '#000000',
          alt: '#040404',
          light: '#1E2019',
        },
        light: {
          DEFAULT: '#C0C0C0',
          alt: '#CBCED1',
          alt2: '#D3D3D3',
          light: '#E4E7EA',
          light2: '#DDDDDD',
          light3: '#D3D5D4',
          dark: '#8C8C8C',
          accent: '#D8F2FC',
          accent2: '#E1F4CB'
        },
        dark: {
          DEFAULT: '#8C8C8C',
          alt: '#31373E',
          alt2: '#374151',
          light: '#D3D3D3',
          // dark: '#333333',
          dark: '#282828',
          dark2: '#272727',
          dark3: '#353531',
          dark4: '#423E37',
          accent: '#616161',
          accent2: '#9DB5B2',
          accent3: '#6D7A8A'
        },
        green: {
          DEFAULT: '#00AC82',
          alt: '#00AF54',
          alt2: '#28965A',
          alt3: '##1EB324',
          light: '#00BD8E',
          light2: '#00C2A7',
          light3: '#2BFFAB',
          light4: '#D5F2E3',
          dark: '#008B73',
          dark2: '#008175',
          dark3: '#116614',
          accent: '#06A77D',
          accent2: '#166646',
          accent3: '#09BC8A'
        },
        blue: {
          DEFAULT: '#0081CC',
          alt: '#0070FF',
          light: '#009CFF',
          dark: '#17255A',
          accent: '#00A5C8',
          accent2: '#1888A3',
          accent3: '#258EA6',
          accent4: '#31ADE3'
        },
        purple: {
          DEFAULT: '#4E27ED',
          alt: '#4F1FFF',
          dark: '#271033',
          dark2: '#38276E'
        },
        pink: {
          DEFAULT: '#F500C3',
          light: '#F0ADFF',
          accent: '#FF1082',
          accent2: '#DB504A',
          accent3: '#E85F5C'
        },
        yellow: {
          DEFAULT: '#FFD639',
          alt: '#FFC800',
          light: '#E0CA3C',
          light2: '#FED766'
        },
        orange: {
          DEFAULT: '#F08700',
          alt: '#FA8334',
          alt2: '#FF8427',
          light: '#F79256',
          dark: '#BA2D0B',
          dark2: '#BC412B',
          accent: '#FF6B35',
          accent2: '#DE6B48',
          accent3: '#D1603D'
        },
        violet: {
          DEFAULT: '#7A306C',
          dark: '#380036'
        },
        ruby: '#931621',
        brown: {
          DEFAULT: '#D9981F'
        }
      }
    }
  },
  plugins: [
    plugin(({addUtilities}) => {
      addUtilities({
        'font-spacing-lg': {
          lineHeight: 52,
          letterSpacing: -0.5
        },
        'font-spacing-md': {
          lineHeight: 32,
          letterSpacing: -0.5
        },
        'font-spacing': {
          lineHeight: 24,
          letterSpacing: -0.3
        },
        'font-spacing-sm': {
          lineHeight: 19,
          letterSpacing: -0.3
        },
        'outline-none': {
          outline: '2px solid transparent',
          outlineOffset: '2px'
        },
        'shadow': {
          shadowColor: `#000`,
          shadowRadius: 0.625,
          shadowOpacity: 0.125,
          shadowOffset: { width: 1, height: 1 },
          elevation: 2.25
        },
        'shadow-left': {
          shadowColor: `#000`,
          shadowRadius: 0.625,
          shadowOpacity: 0.125,
          shadowOffset: { width: 1, height: 1 },
          elevation: 2.25
        },
        'shadow-md': {
          shadowColor: `#000`,
          shadowRadius: 0.9375,
          shadowOpacity: 0.25,
          shadowOffset: { width: 0, height: 0 },
          elevation: 3.625
        },
        'shadow-md-left': {
          shadowColor: `#000`,
          shadowRadius: 0.9375,
          shadowOpacity: 0.25,
          shadowOffset: { width: -1, height: 1 },
          // elevation: 3.625
        },
        'shadow-lg': {
          shadowColor: `#000`,
          shadowRadius: 1.25,
          shadowOpacity: 0.375,
          shadowOffset: { width: 1, height: 1 },
          // elevation: 5
        },
        'shadow-white': {
          shadowColor: `#E4E7EA`,
          shadowRadius: 0.625,
          shadowOpacity: 0.125,
          shadowOffset: { width: 1, height: 1 },
          elevation: 2.25
        },
      });
    })
  ]
}
