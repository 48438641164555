import {initializeApp, getApps, getApp} from 'firebase/app'
import {getAnalytics, isSupported as analyticsSupported, logEvent} from 'firebase/analytics'

let firebase = null
let analyticsObj = null

const firebaseConfig = {
	apiKey: 'AIzaSyDwK2F8iH04Ll7po2zo0dvoo-dXHILZfzA',
	authDomain: 'bybeapp-667bd.firebaseapp.com',
	projectId: 'bybeapp-667bd',
	storageBucket: 'bybeapp-667bd.appspot.com',
	messagingSenderId: '632369485093',
	appId: '1:632369485093:web:4d00e6d2d0eb73bf8236cc',
	measurementId: 'G-FE248RQLWM'}

function initFirebase({useAnalytics = false}) {
	return new Promise(function(resolve) {
		const appExists = getApps().length > 0
		if (appExists) firebase = getApp()
		else firebase = initializeApp(firebaseConfig)

		if (useAnalytics && analyticsSupported()) {
			analyticsObj = getAnalytics(firebase)
			analyticsObj.pageView = function(pageName, path) {
				logEvent(analyticsObj, 'screen_view', {
					firebase_screen: pageName,
					firebase_screen_class: path})
			}

			analyticsObj.event = function(name, value) {
				logEvent(analyticsObj, name, value)}
		}
		resolve({analytics})
	})
}

function analytics() {
	return analyticsObj}

export {firebase, analytics, initFirebase}
