import {useContext, useState} from "react"
import {View, Text, TextInput, Pressable} from "react-native";
import {ajaxRequest} from "js-utilities";
import parseNestedErrors from "../assets/functions/parseNestedErrors.js";

import Context from "./context.jsx";

import Page from "./ui/page.jsx";
import Button from "./ui/button.jsx";
import LoadingSpinner from "./ui/loadingSpinner.jsx";
import ChevronLeftIcon from "./icons/chevronLeftIcon.jsx";


export default function Verify() {
	const {tw, auth, config, verifyPhoneNumber, currentGradient, displayStatusMessage} = useContext(Context);

  const [phoneNumber, setPhoneNumber] = useState('');
  const [code, setCode] = useState('');
  const [codeSent, setCodeSent] = useState(false);
  const [loading, setLoading] = useState(false);

  function submit() {
    setLoading(true);
    if (!phoneNumber.match(/^(?:\d{11}|\d{3}-\d{3}-\d{4}|\d{10})$/)){
      setLoading(false);
      return displayStatusMessage({type: 'error', message: 'Invalid Phone Number', details: 'Please enter a valid phone number.'});}
    ajaxRequest( 'POST', `${config.apiEndpoint}/auth/send_sms_code`, {
      headers: auth.authHeaders,
      params:{
        phone_number: phoneNumber
      }
    } ).then (res => {
      setCodeSent(true);
      setLoading(false);
      setPhoneNumber('');
      displayStatusMessage({type: 'success', message: 'Code Sent', details: 'Your Code has Been Sent'});

    }).catch(res => {
      setLoading(false)
      const {trail, errors} = parseNestedErrors(res.body.errors)[0];
      displayStatusMessage({type: 'error',
        message: 'Whoops, something went wrong',
        details: `${trail[trail.length - 1]} ${errors[0]}`});
    });
  }

  function verify() {
    verifyPhoneNumber(code);}

  function handlePhoneNumberChange(event) {
		var input = event;
		var regex = /[\d-]+/;

		if (input === '' || regex.test( input[input.length - 1] )) {
			if (input.length <= phoneNumber.length) {
				// erasing with '-' at end
        if(phoneNumber[phoneNumber.length - 1] === '-') return setPhoneNumber(input.slice(0,input.length-1));
        // Erasing without '-' at end
				return setPhoneNumber(input);
			} else {
				// typing
				if (input.length === 3 || input.length === 7) return setPhoneNumber( input.replace(/$/,'-') );
				return setPhoneNumber(input);
			}
		}
	}

  return <Page header='Verification'>
    <View style={tw`w-full`}>
      {loading ? <LoadingSpinner /> : !codeSent ? <>
        <Text style={tw`mb-xs text-lg text-dark-dark font-primaryMedium font-spacing`}>Enter Your Phone Number</Text>
        <Text style={tw`mb-xs text-xs text-dark-dark font-primary font-spacing-sm`}>This Phone Number Will be Used for Venmo Payouts</Text>

        <TextInput style={tw`mb-base p-xs border border-dark rounded-sm text-lg font-primary font-spacing outline-none`}
          value={phoneNumber}
          onChangeText={event => handlePhoneNumberChange(event)}
          maxLength={12}
          editable={!codeSent}
          keyboardType='phone-pad'
          autoCompleteType='tel'
          textContentType="telephoneNumber" />

        <Button onPress={submit}
          color={currentGradient[0]}
          text={'Get Code'} />
      </> : <>
        <Text style={tw`mb-xs text-lg text-dark-dark font-primaryMedium font-spacing`}>Enter the 6 digit code</Text>

        <TextInput style={tw`mb-base p-xs border border-dark rounded-sm text-lg font-primary font-spacing outline-none`}
          value={code}
          onChangeText={code => setCode(code)}
          editable={codeSent}
          keyboardType='number-pad'
          autoCompleteType="one-time-code"
          textContentType="oneTimeCode" />
        
        <Button onPress={verify}
          color={currentGradient[0]}
          text={'Submit'} />

        <Pressable style={tw`flex-row items-center absolute bottom-2 left-0`}
          onPress={() => setCodeSent(false)}>
          <ChevronLeftIcon color={tw`text-primary`.color} size={5} />
          <Text style={tw`text-dark-dark font-primaryMedium font-spacing`}>Back</Text>
        </Pressable>
      </>}
    </View>
  </Page>;
}
