import {View, Text, Modal, Pressable} from 'react-native';
import {useContext, useState, useEffect, useRef} from 'react';
import {Camera, CameraType} from 'expo-camera';
import {LinearGradient} from 'expo-linear-gradient';

import {uuid, pSBC} from 'js-utilities';

import Context from './context';
import UiBlurView from './ui/uiBlurView';
import XIcon from './icons/xIcon';
import LoadingSpinner from "./ui/loadingSpinner";

export default function CameraModal({visible, setVisible, images, setImages}) {
  const {tw,
    isWeb,
    isAndroid,
    isDesktopWeb,
    currentGradient,
    displayStatusMessage} = useContext(Context);

  const defaultDimensions = {width: 9, height: 16};

  const [cameraType, setCameraType] = useState(CameraType.back);
  const [cameraPermission, requestCameraPermissionsAsync, getCameraPermissionsAsync] = Camera.useCameraPermissions();
  const [cameraDimensions, setCameraDimensions] = useState(defaultDimensions);
  const [cameraReady, setCameraReady] = useState(false);

  const cameraRef = useRef();

  useEffect(() => {
    if (visible) getCameraPermissionsAsync().then(({granted, canAskAgain}) => {
      if (!granted && canAskAgain) requestCameraPermissionsAsync().then(({granted}) => {
        if (!granted) displayStatusMessage({type: 'error', message: 'Permission Denied', details: 'Camera Permission Denied'});
      }).catch(err => displayStatusMessage({type: 'error', message: 'Something Went Wrong'}))
    }).catch(err => displayStatusMessage({type: 'error', message: 'Something Went Wrong'}))
  }, [visible]);
  
  function onCameraReady() {
    setCameraReady(true);
    if (isAndroid && cameraRef.current) cameraRef.current.getSupportedRatiosAsync().then(ratios => {
      setCameraDimensions(ratios.reduce((tallestRatio, currentRatio) => {
        const [height, width] = currentRatio.split(':').map(Number);
        if (height > (tallestRatio?.height || 0)) {
          tallestRatio.height = height;
          tallestRatio.width = width;}
        return tallestRatio;
      }, {}));
    }).catch(function() {
      displayStatusMessage({type: 'error', message: 'Something Went Wrong'})
    })}

  function takePhoto() {
    if (cameraRef.current && cameraReady) {
      cameraRef.current.takePictureAsync({
        base64: true,
        quality: 0.25
      }).then(function(photo) {
        const nativeDataUriPrefix = 'data:image/jpeg;base64,'
        let dataUri;
        if (isWeb) dataUri = photo.base64
        else dataUri = nativeDataUriPrefix + String(photo.base64)

        setImages([...images, {uuid: uuid(), dataUri}])
        setVisible(!visible)
      }).catch(function() {
        displayStatusMessage({type: 'error', message: 'Something Went Wrong'})
      })}}

  return <Modal visible={visible} transparent={true}>
    <UiBlurView />

    <View style={tw`w-full h-full items-center justify-center`}>
      <View style={tw`justify-center w-full h-full ${isDesktopWeb ? 'max-w-256 max-h-144' : ''} p-base`}>
        <View style={tw`w-full ${isAndroid ? `aspect-${cameraDimensions.width}/${cameraDimensions.height}` : 'h-full'} rounded-lg overflow-hidden`}>
          <Camera ref={cameraRef} style={tw`justify-between w-full h-full`}
            ratio={`${cameraDimensions.height}:${cameraDimensions.width}`}
            type={cameraType}
            onCameraReady={onCameraReady}>
            <Pressable style={tw`m-xs ml-auto`} onPress={() => setVisible(!visible)}>
              <XIcon size={10} strokeWidth={2.5} color={tw`text-light`.color}/>
            </Pressable>

            {cameraReady ? <LinearGradient style={tw`justify-center m-base px-sm py-xs rounded-lg shadow`}
              colors={[
                pSBC(-0.33, currentGradient[1]), pSBC(-0.33, currentGradient[1]),
                pSBC(-0.25, currentGradient[1]), pSBC(-0.5, currentGradient[1])
              ]}
              locations={[0, 0.33, 0.67, 1]}
              start={{x: 0.12, y: 0.12}}
              end={{x: 0.97, y: 0.97}}>
              <Pressable onPress={takePhoto}>
                <Text style={tw.style(`text-center text-xl text-white font-primaryMedium font-spacing`, {
                  textShadowColor: tw`text-light-light`.color,
                  textShadowRadius: 1
                })}>Take Photo</Text>
              </Pressable>
            </LinearGradient> : <LoadingSpinner/>}
          </Camera>
        </View>
      </View>
    </View>
  </Modal>;
}
