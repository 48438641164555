import {useState, useEffect} from 'react';

import {ajaxRequest} from 'js-utilities';
import parseNestedErrors from '../../assets/functions/parseNestedErrors.js';


export default function useTokenAuth({config, toForeground, store, navigate, displayStatusMessage}) {
  const [auth, setAuth] = useState(null);
  const [authLoading, setAuthLoading] = useState(false);
  const [authReady, setAuthReady] = useState(false);
  // const [authHeaders, setAuthHeaders] = useState(null);

  function formatAuthHeaders(headers) {
    const accessToken = headers['access-token'] || headers['accesstoken']
    const {client, uid, expiry} = headers
    return [{uid}, {client}, {expiry}, {'access-token': accessToken}]
  }

  // TODO: Check that auth tokens are still valid on app load and logout if not
  useEffect(getUser, [toForeground])

  useEffect(() => {
    store.get('auth')
      .then(function({data, error}) {
        if (error) return error
        setAuth(data)})
  }, []);

  function updateStoreAuth(newAuth) {
    // TODO: Keep track of rememberMe in store and refactor this function to useEffect
    return new Promise(function(resolve) {
      store.set('auth', newAuth)
        .then(function({data, error}) {
          resolve({data, error})
        })
    })
  }

  function login(email, password, rememberMe) {
    return new Promise(function(resolve){ ajaxRequest('POST', `${config.apiEndpoint}/auth/sign_in`, {
      body: {email, password},
      json: true
    }).then(function({body, headers}) {
      const user = body.user
      const authHeaders = formatAuthHeaders(headers)
      const newAuth = {user, authHeaders}
      setAuth(newAuth)
      if (rememberMe) updateStoreAuth(newAuth) // NOTE: Handle error
      resolve({newAuth})})
    .catch(function(error) {
      const {trail, errors} = parseNestedErrors(error.body.errors)[0]
      const details = `${trail[trail.length - 1]} ${errors[0]}`
      displayStatusMessage({type: 'error',
        message: 'Could not login',
        details: 'Check your email and password and try again'})
      resolve({error})
      })
    })}

  function getUser() {
    if (!auth) return
    setAuthLoading(true)

    ajaxRequest('GET', `${config.apiEndpoint}/auth`, {
      json: true,
      headers: auth.authHeaders})
    .then(function({body, headers, success}) {
      setAuthLoading(false)
      setAuthReady(true)
      if (!success) {
        displayStatusMessage({type: 'error', message: 'You have been logged out'})
        return logout()}
      if (rememberMe) updateStoreAuth(newAuth)
      const newUser = body.user
      const verifiedAt = new Date(newUser.verified_at)
      const disabledAt = new Date(newUser.disabled_at)
      const newAuthHeaders = formatAuthHeaders(headers)
      const newAuth = {user:
        {...newUser,
          verifiedAt,
          disabledAt},
        authHeaders:
          newAuthHeaders}

      setAuth(newAuth)
    }).catch(function({body, headers}) {
      setAuthLoading(false)
      setAuthReady(true)})
  }

  function signup(email, password, firstName, lastName, dateOfBirth, zipCode, rememberMe, callback) {
    return new Promise (function(resolve) {ajaxRequest('POST', `${config.apiEndpoint}/auth`, {
      body: {
        email: email,
        password: password,
        first_name: firstName,
        last_name: lastName,
        date_of_birth: dateOfBirth,
        zip_code: zipCode
      },
      json: true
    } ).then(res => {
      login(email, password, rememberMe).then((res)=>{
        navigate('/verify')
        resolve(res)
      })
    }).catch(function(error) {
      const {trail, errors} = parseNestedErrors(error.body.errors)[0]
      displayStatusMessage({type: 'error',
        message: 'Whoops, something went wrong',
        details: `${trail[trail.length - 1]} ${errors[0]}`})
      resolve({error})
		})})
  }

  function updateAccount(birthDay, email, firstName, lastName, password, newPassword, rememberMe){
    var ajaxUrlString

		if (newPassword) {
			ajaxUrlString = `${config.apiEndpoint}/auth?date_of_birth=${birthDay}&email=${email}&first_name=${encodeURIComponent(firstName)}&last_name=${encodeURIComponent(lastName)}&current_password=${encodeURIComponent(password)}&password=${encodeURIComponent(newPassword)}`
		} else {
			ajaxUrlString = `${config.apiEndpoint}/auth?date_of_birth=${birthDay}&email=${email}&first_name=${encodeURIComponent(firstName)}&last_name=${encodeURIComponent(lastName)}&current_password=${encodeURIComponent(password)}`
		}
		return new Promise(function(resolve) { ajaxRequest( 'PUT', ajaxUrlString, {
			headers: auth.authHeaders,
		} ).then(function({body, headers}) {
      const user = body.user
      const authHeaders = formatAuthHeaders(headers)
      const newAuth = {user, authHeaders}
      setAuth(newAuth)
      if (rememberMe) updateStoreAuth(newAuth)
      resolve({newAuth})
		} ).catch(function(error) {
      const {trail, errors} = parseNestedErrors(error.body.errors)[0]
      displayStatusMessage({type: 'error',
        message: 'Whoops, something went wrong',
        details: `${trail[trail.length - 1]} ${errors[0]}`})
      resolve({error})
		})})
  }

  function logout() {
    store.remove('auth')
      .then(function() {
        navigate('/')
        setAuth(null)
      })} // NOTE: Handle error

  function verifyPhoneNumber(code) {
    ajaxRequest( 'GET', `${config.apiEndpoint}/auth/verify?code=${code}`, {
			headers: auth.authHeaders
		} ).then(function({body, headers}) {
      const user = body.user
      const newAuth = {...auth, user}
      setAuth(newAuth)
      store.get('auth')
        .then(function({data, error}) {
          if (data) updateStoreAuth(newAuth)})
      navigate('/')
		} ).catch(res => {
      const {trail, errors} = parseNestedErrors(res.body.errors)[0]
      displayStatusMessage({type: 'error',
        message: 'Whoops, something went wrong',
        details: `${trail[trail.length - 1]} ${errors[0]}`})
		})
  }

  return {auth, authLoading, authReady,
    getUser, login, logout, signup, updateAccount,
    verifyPhoneNumber}
}
