import {Platform, Dimensions} from 'react-native';

export default function usePlatform() {
  const {width=9, height=16} = Dimensions.get('window')
  const aspectRatio = width / height
  // TODO: Lock orientation to portrait on mobile and mobile web
  // https://developer.mozilla.org/en-US/docs/Web/API/CSS_Object_Model/Managing_screen_orientation#preventing_orientation_change

  const android = Platform.OS === 'android'
  const ios = Platform.OS === 'ios'
  const web = Platform.OS === 'web'
  const desktopWeb = web && aspectRatio && aspectRatio > 1
  const isPlatformMap = {android, ios, web, desktopWeb}

  const currentPlatform = Object.keys(isPlatformMap)
    .find(platform => isPlatformMap[platform])

  return {currentPlatform,
    isAndroid: android,
    isIos: ios,
    isWeb: web,
    isDesktopWeb: desktopWeb}
}
