import {useContext} from "react";
import {Svg, Circle, Path} from 'react-native-svg';

import Context from "../context.jsx";


export default function NewIcon({size, color, backgroundColor, style}) {
  const {tw} = useContext(Context);

  return <Svg xmlns="http://www.w3.org/2000/svg"
    style={tw.style(style ? style : null, {height: size, width: size})}
    fill={backgroundColor}
    viewBox="0 0 1026 1026"
    strokeWidth={1.5}
    stroke={backgroundColor}>

    <Circle fill={color}
      cx='50%'
      cy='50%'
      r='47%' />

    <Path transform="matrix(1 0 0 -1 0 963)"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M512 934.4c268.631 0 486.4-217.769 486.4-486.4s-217.769-486.4-486.4-486.4c-268.631 0-486.4 217.769-486.4 486.4s217.769 486.4 486.4 486.4zM629.56 731.816l-117.56-130.216-117.56 130.216 8.949-175.204-175.204 8.949 130.216-117.56-130.216-117.56 175.204 8.949-8.949-175.204 117.56 130.216 117.56-130.216-8.949 175.204 175.204-8.949-130.216 117.56 130.216 117.56-175.204-8.949 8.949 175.204z" />
  </Svg>;
}
