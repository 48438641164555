import {useState, useContext} from 'react';
import {View, Text, Pressable, Image} from "react-native";

import {formatUtcDate, formatMoneyCondensed} from 'js-utilities';

import ChevronUpIcon from './icons/chevronUpIcon.jsx';
import ChevronDownIcon from './icons/chevronDownIcon.jsx';
import Context from "./context.jsx";

export default function Purchase({redemption, lastItem}) {
  const {tw, currentGradient} = useContext(Context);

  const [expanded, setExpanded] = useState(false);
  const mediaUploads = redemption.media_upload_images;

  const formatRedemptionStatus = status => {
    switch(status) {
      case 'pending':
        return 'in review';
      case 'accepted':
        return 'approved';
      case 'disbursed':
        return 'cashed out';
      case 'completed':
        return 'cashed out';
      case 'processing':
        return 'cashed out';

      case 'rejected_invalid_product':
        return 'rejected';
      case 'rejected_household_limit':
        return 'rejected';
      case 'rejected_invalid_store':
        return 'rejected';
      case 'rejected_invalid_state':
        return 'rejected';
      case 'rejected_offer_expired':
        return 'rejected';
      case 'rejected_budget_reached':
        return 'rejected';
      case 'rejected_limit_reached':
        return 'rejected';
      case 'rejected_invalid_quantity':
        return 'rejected';
      case 'rejected_offer_not_clipped':
        return 'rejected';

      case 'failed_payment_method':
        return 'failed';
      case 'failed_no_redemptions':
        return 'failed';
      default:
        return status;}}

  return <View style={tw`ml-sm mt-sm rounded-md bg-white border border-light shadow`}>
    <Pressable onPress={() => setExpanded(!expanded)}
      style={tw`flex-row items-center p-xs`}>

      <Text style={tw`mr-sm text-base text-green-accent font-primaryBold`}>
        {formatMoneyCondensed(redemption?.offer.discount_cents)}
      </Text>

      <Text style={tw`text-left flex-1 font-primaryMedium`}>
        {redemption.offer.name} ({redemption.offer.id})
      </Text>

      <View style={tw`ml-auto`}>
        {expanded ? <ChevronUpIcon color={tw`text-[${currentGradient[1]}]`.color} size={5} />
        : <ChevronDownIcon color={tw`text-[${currentGradient[1]}]`.color} size={5} /> }
      </View>
    </Pressable>

    {expanded ? <>
      <View style={tw`flex-row justify-around items-center pb-xs`}>
        <Text style={tw`text-left`}>
          <Text style={tw`font-primaryMedium`}>Date:</Text> {formatUtcDate(new Date(redemption.purchase_date.slice(0, 10)))}
        </Text>

        <Text style={tw`text-left`}>
        <Text style={tw`font-primaryMedium`}>Status:</Text> {formatRedemptionStatus(redemption.status)}
        </Text>
      </View>
    
      {mediaUploads.length ? <View style={tw`flex-row flex-wrap justify-center w-full`}>
        {mediaUploads.map((mui, index) => <View key={`mediaUploadImage-${index}`} style={tw`w-1/2 max-w-64 aspect-square m-xs`}>
          <Image style={tw`w-full h-full rounded-sm bg-white shadow-sm`}
            source={{uri: mui.image_url}} />
        </View>)}
      </View> : null}
    </> : null}
  </View>;
}
