import {registerRootComponent} from "expo";
import {SafeAreaProvider} from "react-native-safe-area-context";
import {useDeviceContext} from "twrnc";
import {Router} from "./components/utilities/router.jsx";
import {Provider} from "./components/context.jsx";
import {NavigateProvider} from "./components/utilities/navigateContext.jsx";
import tw from "./assets/functions/twInit.js";
import 'react-native-url-polyfill/auto';

import App from "./app";

function RootComponent() {
  useDeviceContext(tw);

  return <Router>
    <NavigateProvider>
      <SafeAreaProvider>
        <Provider>
          <App />
        </Provider>
      </SafeAreaProvider>
    </NavigateProvider>
  </Router>;
}

// registerRootComponent calls AppRegistry.registerComponent("main", () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
registerRootComponent(RootComponent);
