import {useContext, useEffect} from 'react';
import {SafeAreaView, BackHandler} from 'react-native';
import {StatusBar} from 'expo-status-bar';

import {
  Routes, Route, Navigate, redirect, NavigateWithParams, useLocation, mobilePlatform,
} from './components/utilities/router.jsx';
import Context from './components/context.jsx';

import Header from './components/header.jsx';
import Menu from './components/menu.jsx';
import StatusMessage from './components/statusMessage.jsx';
import OffersPage from './components/offersPage.jsx';
import Login from './components/login.jsx';
import ForgotPassword from './components/forgotPassword.jsx';
import NewPassword from './components/newPassword.jsx';
import Account from './components/account.jsx';
import LocationPage from './components/locationPage.jsx';
import OfferPage from './components/offerPage.jsx';
import SignupPage from './components/signupPage.jsx';
import Verify from './components/verify.jsx';
import Balance from './components/balance.jsx';
import TutorialModal from './components/tutorialModal.jsx';
import AgePage from './components/agePage.jsx';
import Faq from './components/faq.jsx';
import LoadingSpinner from './components/ui/loadingSpinner.jsx';


export default function App() {
  const {tw,
    fontsLoaded,
    auth,
    menuOpen,
    setMenuOpen,
    navigate,
    store,
    setModalVisible} = useContext(Context)

  useEffect(() => {
    store.get('showModal')
    .then(function({data, error}) {
      if (error) return error
      setModalVisible(data);
      return data})}, []);

  useEffect(() => {
    function backAction() {
      navigate(-1)
      return true}

    const backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      backAction)

    return function() {backHandler.remove();}
  }, [])

  const appReady = fontsLoaded

  return <>
  {appReady ?
    <SafeAreaView style={tw.style(`flex-1 w-full bg-primary font-primary`)}>
      <Header menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <TutorialModal />

      <Routes>
        <Route exact path='/' element={<OffersPage />} />
        <Route path='/age' element={<AgePage />} />
        <Route path='/location' element={<LocationPage />} />
        <Route path='/offer/:id' element={<OfferPage />} />
        <Route path='/faq' element={<Faq />} />
        <Route path='/offer-detail/:state/:id' element={
          <NavigateWithParams to={params => `/offer/${params.id}`} replace />
        } />

        { auth ? <>
          <Route path='/account' element={<Account />} />
          <Route path='/verify' element={<Verify />} />
          <Route path='/balance' element={<Balance />} />
        </> : <>
          <Route path='/login' element={<Login />} />
          <Route path='/signup' element={<SignupPage />} />
          <Route path='/reset-password' element={<ForgotPassword />} />
          <Route path='/reset-password-2' element={<NewPassword />} />
        </> }

        <Route path='*' element={<Navigate to='/' replace />} />
      </Routes>

      <Menu />
      <StatusMessage />
    </SafeAreaView> : <LoadingSpinner />}
    <StatusBar style='light' backgroundColor={tw`text-primary-dark`.color} translucent={false} />
  </>
}
