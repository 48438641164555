import React, {useContext, useState} from "react"
import {View, Text, TextInput, Switch} from "react-native";

import {emailValid} from 'js-utilities';

import Context from "./context.jsx";
import Page from "./ui/page.jsx";
import Button from "./ui/button.jsx";
import LoadingSpinner from "./ui/loadingSpinner.jsx";


export default function SignupPage() {
	const {tw, signup, currentGradient, displayStatusMessage, navigate} = useContext(Context);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [zipCode, setZipCode] = useState('43026');
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);

  function handleDateOfBirthChange(event) {
		var input = event;
		var regex = /[\d-]+/;

		if (input === '' || regex.test(input[input.length - 1])) {
			if (input.length <= dateOfBirth.length) {
				// erasing
        if(dateOfBirth[dateOfBirth.length - 1] === '-') return setDateOfBirth(input.slice(0,input.length-1));

				setDateOfBirth(input);
			} else {
				// typing
				if (input.length === 2 || input.length === 5) setDateOfBirth(input.replace(/$/,'-'));
				else setDateOfBirth(input);
			}
		}
	}

  function validateInput(input, type) {
    switch (type) {
      case 'email':
        if (emailValid(input)) return true;
        else {
          setLoading(false);
          return displayStatusMessage({type: 'error', message: 'Invalid Email', details: 'Please enter a valid email address'});}
      case 'password':
        if (input.length < 8){
          setLoading(false);
          return displayStatusMessage({type: 'error', message: 'Invalid Password', details: 'Password must be at least 8 characters'});}
        else if (input !== passwordConfirmation){
          setLoading(false);
          return displayStatusMessage({type: 'error', message: 'Invalid Password', details: 'Passwords do not match'});}
        setLoading(false);
        return true;
      case 'birthday':
        if (input.length === 3){
          if(input[2].length < 4 ) {
            setLoading(false)
            return displayStatusMessage({type: 'error', message: 'Invalid Birthday', details: 'Please enter a valid birthday'});}
          if(Math.abs(input[2] -  new Date().getFullYear()) < 21) {
            setLoading(false)
            return displayStatusMessage({type: 'error', message: 'Invalid Birthday', details: 'You must be at least 21 years old to create an account'});}
          return true;}
        else 
        setLoading(false)
        return displayStatusMessage({type: 'error', message: 'Invalid Birthday', details: 'Please enter a valid birthday'});
      default:
        setLoading(false);
        return displayStatusMessage({type: 'error', message: 'Error', details: 'Input is invalid'});}}

  function handleSubmit() {
    setLoading(true);
    
    const dateOfBirthArray = dateOfBirth.split('-');
    if (!validateInput(email, 'email')) return;
    if (!validateInput(password, 'password')) return;
    if (!validateInput(dateOfBirthArray, 'birthday')) return;
    const formattedDateOfBirth = `${dateOfBirthArray[2]}-${dateOfBirthArray[0]}-${dateOfBirthArray[1]}`;

    signup(email, password, firstName, lastName, formattedDateOfBirth, zipCode, rememberMe).then(res =>{
      setLoading(false);
      navigate('/verify')
    });}

  return <Page header="Create Account">
    <View style={tw`w-full`}>
      <Text style={tw`mb-xs text-lg text-dark-dark font-primaryMedium font-spacing`}>First Name</Text>
      <TextInput style={tw`mb-sm p-xs rounded-sm border border-dark text-lg font-primary font-spacing`}
        value={firstName}
        textContentType="givenName"
        onChangeText={firstName => setFirstName(firstName)} />

      <Text style={tw`mb-xs text-lg text-dark-dark font-primaryMedium font-spacing`}>Last Name</Text>
      <TextInput style={tw`mb-sm p-xs rounded-sm border border-dark text-lg font-primary font-spacing`}
        value={lastName}
        textContentType="familyName"
        onChangeText={lastName => setLastName(lastName)} />

      <Text style={tw`mb-xs text-lg text-dark-dark font-primaryMedium font-spacing`}>Birthday</Text>
      <TextInput style={tw`mb-sm p-xs rounded-sm border border-dark text-lg font-primary font-spacing`}
        keyboardType='number-pad'
        value={dateOfBirth}
        maxLength={10}
        placeholder="MM-DD-YYYY"
        placeholderTextColor={tw`text-dark`.color}
        onChangeText={event => handleDateOfBirthChange(event)} />

      <Text style={tw`mb-xs text-lg text-dark-dark font-primaryMedium font-spacing`}>Email</Text>
      <TextInput style={tw`mb-sm p-xs rounded-sm border border-dark text-lg font-primary font-spacing`}
        value={email}
        autoCompleteType="email"
        textContentType="emailAddress"
        onChangeText={email => setEmail(email)} />

      <Text style={tw`mb-xs text-lg text-dark-dark font-primaryMedium font-spacing`}>Password</Text>
      <TextInput style={tw`mb-sm p-xs rounded-sm border border-dark text-lg font-primary font-spacing`}
        onChangeText={password => setPassword(password)}
        secureTextEntry={true}
        autoCorrect={false}
        textContentType="NewPassword" />
        
      <Text style={tw`mb-xs text-lg text-dark-dark font-primaryMedium font-spacing`}>Password Confirmation</Text>
      <TextInput style={tw`mb-sm p-xs rounded-sm border border-dark text-lg font-primary font-spacing`}
        secureTextEntry={true}
        autoCorrect={false}
        onChangeText={passwordConfirmation => setPasswordConfirmation(passwordConfirmation)} />

      <View style={tw`flex-row items-center mb-sm`}>
        <Switch style={tw`mr-xs`}
          value={rememberMe}
          onValueChange={() => setRememberMe(!rememberMe)}
          trackColor={{false: tw`text-dark-light`.color, true: currentGradient[1]}}
          thumbColor={currentGradient[0]}
          ios_backgroundColor={{false: tw`text-dark-light`.color, true: currentGradient[1]}} />

        <Text style={tw`text-lg text-dark-dark font-primaryMedium font-spacing`}>
          Remember Me
        </Text>
      </View>

      {loading ? <LoadingSpinner/> : <Button onPress={handleSubmit}
        color={currentGradient[0]}
        text='Sign Up' />}
    </View>
  </Page>
}
