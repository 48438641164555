import {Text, View, ActivityIndicator} from "react-native";

import {useContext} from "react";

import Context from "../context.jsx";


export default function LoadingSpinner({size = 50, color, extraStyle}) {
  const {tw} = useContext(Context);
  
  return <View style={tw.style(`flex-1 items-center justify-center`, {...extraStyle})}>
    <ActivityIndicator size={size} color={color ? color : tw`text-light`.color} />
  </View>;
}
