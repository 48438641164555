import {createContext} from 'react';

import {useNavigate} from './router.jsx';


const NavigateContext = createContext();
export function NavigateProvider({children}) {
  const routerNavigate = useNavigate();

  function navigate(path) {
    routerNavigate(path);}

  return <NavigateContext.Provider value={{navigate}}>
    {children}
  </NavigateContext.Provider>;
}

export default NavigateContext;
