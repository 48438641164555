import { useState, useContext, useEffect } from "react";
import {View, Text, Modal, Pressable, Image, FlatList} from "react-native";
import {Svg, Path} from 'react-native-svg';

import ImageOne from '../assets/images/tutorial/1.png';
import ImageTwo from '../assets/images/tutorial/2.png';
import ImageThree from '../assets/images/tutorial/3.png';
import ImageFive from '../assets/images/tutorial/5.png';
import ImageSix from '../assets/images/tutorial/6.png';
import ImageSeven from '../assets/images/tutorial/7.png';
import ImageEight from '../assets/images/tutorial/8.png';
import ImageNine from '../assets/images/tutorial/9.png';
import ImageTen from '../assets/images/tutorial/10.png';
import ImageEleven from '../assets/images/tutorial/11.png';
import ImageTwelve from '../assets/images/tutorial/12.png';

import Button from './ui/button.jsx';

import Context from "./context.jsx";
import UiBlurView from './ui/uiBlurView.jsx';


export default function TutorialModal() {
  const {tw, isWeb, store, modalVisible, setModalVisible, auth, navigate, age, selectedState} = useContext(Context);

  const [page, setPage] = useState(0);

  useEffect(() => {
    // TODO: Refactor?
    store.set('showModal', false)
      .catch(function({error}) {
        // NOTE: Handle error
      })
  }, [])

  useEffect(() => {
    setPage(0)
  }, [])

  function closeModal() {
    setModalVisible(false);
    setPage(0);
  }

  let waitUntillDone = age && selectedState;

  return <Modal
    transparent={true}
    visible={waitUntillDone ? modalVisible : false}
    onRequestClose={() => setModalVisible(!modalVisible)}>

    <UiBlurView onPress={() => setModalVisible(false)} />

    <View style={tw`w-full h-full items-center justify-center z-20`}>
      <Pressable style={tw.style(`absolute w-full h-full`)} onPress={() => setModalVisible(false)} />
      
      <View style={tw`justify-center bg-white text-center mx-auto ${isWeb ? `min-h-[530px] w-90` : `min-h-[530px] w-81`} rounded`}>
        <Pressable style={tw`h-7 w-7 absolute top-1 right-1 p-0.25xs z-20`} onPress={() => closeModal()}>
          <XIcon/>
        </Pressable>
        {auth ?
        <View style={tw`flex-grow px-1.25`}>
          {{
            0: <PageOneSignedIn/>,
            1: <PageTwoSignedIn/>,
            2: <PageThreeSignedIn/>,
            3: <PageFourSignedIn/>,
            4: <PageFiveSignedIn/>,
            5: <PageSixSignedIn/>
          } [page] || null}
        </View> 
         :<View style={tw`flex-grow px-1.25`}>
        {{
            0: <PageOne/>,
            1: <PageTwo/>,
            2: <PageThree/>,
            3: <PageFour/>,
            4: <PageFive/>
          } [page] || null}
        </View>}

      {auth && page === 5 || !auth && page === 4 ? <View style={tw`py-1.25 justify-center`}>
          {auth ? <Button onPress={() => closeModal()} 
            color={tw`text-primary`.color}
            text='Finish' />
          :<>
            <Button onPress={() => {
              closeModal()
              navigate('/login')}}
              extraStyle={tw`mb-xs`}
              color={tw`text-primary`.color}
              text='Login'
              width={'36'} />

            <Button onPress={() => {
              closeModal()
              navigate('/signup')}}
              color={tw`text-primary`.color}
              text='Sign Up'
              width={'36'} />
          </>}
        </View>
        :<View style={tw`flex-row p-1.25 justify-between`}>
          {page === 0 ? <Button onPress={() => closeModal()}
            color={tw`text-primary`.color}
            text='Close' />
          :<Button onPress={() => setPage(page-1)}
            color={tw`text-primary`.color}
            text='Back' />}

          <Button onPress={() => setPage(page+1)} 
            color={tw`text-primary`.color}
            text='Next' />
        </View>}
      </View>
    </View>
  </Modal>

  function PageOne() {
    return <>
      <Text style={tw`mb-sm text-left text-4.5xl text-black font-bold font-spacing-md`}>Getting Started (1/5)</Text>
      
      <Image style={tw`mx-auto mb-1.25 ${isWeb ? `w-[261px] h-[211px]` : ''}`}
        source={ImageOne}
        alt={'Page One Image'}
      />
      
      <Text style={tw`mb-1.25 text-center text-xl uppercase text-primary-dark font-secondaryBold font-spacing`}>Earn Cash Back rebates on <Text style={{ whiteSpace: 'nowrap' }}>Beer, Wine, and Spirits</Text></Text>

      <Text style={tw`text-gray-600 text-left font-spacing-sm`}>
        You need an account to upload reciepts and receive cash back on digital alcohol offers. 
        Over 1 million people use BYBE to earn cash back on their favorite beer, wine, and spirit brands.
      </Text>
    </>
  }

  function PageTwo() {
    return <>
      <Text style={tw`mb-sm text-left text-4.5xl text-black font-bold font-spacing-md`}>Getting Started (2/5)</Text>
      
      <Image style={tw`mx-auto mb-1.25 ${isWeb ? `w-[201px] h-[214px]` : ''}`}
        source={ImageTwo}
        alt={'Page Two Image'}
        resizeMethod='resize'
        resizeMode='contain' />
      
      <Text style={tw`mb-1.25 text-center text-xl uppercase text-primary-dark font-secondaryBold font-spacing`}>We Respect Your privacy</Text>
      
      <Text style={tw`text-gray-600 text-left font-spacing-sm`}>
        We do not sell or share your information to any 3rd parties.
      </Text>
    </>
  }

  function PageThree() {
    return <>
      <Text style={tw`mb-sm text-left text-4.5xl text-black font-bold font-spacing-md`}>Getting Started (3/5)</Text>
      
      <Image style={tw`mx-auto mb-1.25 ${isWeb ? `w-[302px] h-[302px]` : ''}`}
        source={ImageThree}
        alt={'Page Three Image'}
        resizeMethod='resize'
        resizeMode='contain' />
      
      <Text style={tw`mb-1.25 text-center text-xl uppercase text-primary-dark font-secondaryBold font-spacing`}>How It Works</Text>
      
      <Text style={tw`text-gray-600 text-left font-spacing-sm`}>
        Once you earn cash back, we will send you an email with instructions on how to redeem via Paypal, Venmo, or a Visa Prepaid Card.
      </Text>
    </>
  }

  function PageFour() {
    return <>
      <Text style={tw`mb-sm text-left text-4.5xl text-black font-bold font-spacing-md`}>Getting Started (4/5)</Text>
      
      <Image style={tw`mx-auto mb-1.25 ${isWeb ? `w-[220px] h-[211px]` : ''}`}
        source={ImageFive}
        alt={'Page Four Image'}
        resizeMethod='resize'
        resizeMode='contain' />
      
      <Text style={tw`mb-1.25 text-center text-xl uppercase text-primary-dark font-secondaryBold font-spacing`}>Verify Phone Number</Text>
      
      <Text style={tw`text-gray-600 text-left font-spacing-sm mb-0.75xs`}>
        We verify your phone number to make sure you're not a robot. We will never text or call you without your consent.
      </Text>
      
      <Text style={tw`text-gray-600 text-left font-spacing-sm`}>
        If you plan on using Venmo, use the same phone number associated with your Venmo account.
      </Text>
    </>
  }
  
  function PageFive() {
    return <>
      <Text style={tw`mb-sm text-left text-4.5xl text-black font-bold font-spacing-md`}>Getting Started (5/5)</Text>
      
      <Image style={tw`mx-auto mb-1.25 ${isWeb ? `w-[208px] h-[227px]` : ''}`}
        source={ImageSix}
        alt={'Page Five Image'}
        resizeMethod='resize'
        resizeMode='contain' />
      
      <Text style={tw`mb-1.25 text-center text-xl uppercase text-primary-dark font-secondaryBold font-spacing`}>Getting Started</Text>
      
      <Text style={tw`text-gray-600 text-left font-spacing-sm`}>
        Create an account with BYBE to view offers and start submitting receipts to earn cash back on your favorite adult beverages.
      </Text>
    </>
  }

// For Signed in Users

  function PageOneSignedIn() {
    return <>
      <Text style={tw`mb-sm text-left text-4.5xl text-black font-bold font-spacing-md`}>How to Redeem (1/6)</Text>
      
      <Image style={tw`mx-auto mb-1.25 ${isWeb ? 'w-[253px] h-[233px]' : ''}`}
        source={ImageSeven}
        alt={'Page Six Image'}
        resizeMethod='resize'
        resizeMode='contain' />
      
      <Text style={tw`mb-1.25 text-center text-xl uppercase text-primary-dark font-secondaryBold font-spacing`}>Earn Cash Back Rebates on <Text style={{ whiteSpace: 'nowrap' }}>Beer, Wine, And Spirits</Text></Text>
      
      <Text style={tw`text-gray-600 text-left font-spacing-sm font-spacing-sm`}>
        Get cash back within 48 hours. The review process may be delayed around major events and weekends.
      </Text>
    </>
  }

  function PageTwoSignedIn() {
    return <>
      <Text style={tw`mb-sm text-left text-4.5xl text-black font-bold font-spacing-md`}>How to Redeem (2/6)</Text>
      
      <Image style={tw`mx-auto mb-1.25 ${isWeb ? `w-[261px] h-[239px]` : ''}`}
        source={ImageEight}
        alt={'Page Seven Image'}
        resizeMethod='resize'
        resizeMode='contain' />
      
      <Text style={tw`mb-1.25 text-center text-xl uppercase text-primary-dark font-secondaryBold font-spacing`}>View Offers</Text>
      
      <Text style={tw`text-gray-600 text-left font-spacing-sm`}>
        View the latest Adult Beverage offers in your area.
      </Text>
    </>
  }

  function PageThreeSignedIn() {
    return <>
      <Text style={tw`mb-sm text-left text-4.5xl text-black font-bold font-spacing-md`}>How to Redeem (3/6)</Text>
      
      <Image style={tw`mx-auto mb-1.25 ${isWeb ? `w-[252px] h-[231px]` : ''}`}
        source={ImageNine}
        alt={'Page Seven Image'}
        resizeMethod='resize'
        resizeMode='contain' />
      
      <Text style={tw`mb-1.25 text-center text-xl uppercase text-primary-dark font-secondaryBold font-spacing`}>Buy Products</Text>
      
      <Text style={tw`text-gray-600 text-left font-spacing-sm`}>
        Make qualifying purchases from your favorite location where applicable products are sold.
      </Text>
    </>
  }

  function PageFourSignedIn() {
    return <>
      <Text style={tw`mb-sm text-left text-4.5xl text-black font-bold font-spacing-md`}>How to Redeem (4/6)</Text>
      
      <Image style={tw`mx-auto mb-1.25 ${isWeb ? `w-[243px] h-[223px]` : ''}`}
        source={ImageTen}
        alt={'Page Eight Image'}
        resizeMethod='resize'
        resizeMode='contain' />
      
      <Text style={tw`mb-1.25 text-center text-xl uppercase text-primary-dark font-secondaryBold font-spacing`}>Submit Receipts</Text>
      
      <Text style={tw`text-gray-600 text-left font-spacing-sm`}>
        After your purchase, submit a photo of your receipt. 
        <Text style={tw`text-gray-600 text-left font-spacing-sm font-bold`}>
         {` Be sure to include the entire receipt. Digital receipts are not accepted. `}
        </Text>
        If your purchase meets the requirements, you will receive an email and your balance will be updated in the BYBE app.
      </Text>
    </>
  }
  
  function PageFiveSignedIn() {
    return <>
      <Text style={tw`mb-sm text-left text-4.5xl text-black font-bold font-spacing-md`}>How to Redeem (5/6)</Text>
      
      <Image style={tw`mx-auto mb-1.25 ${isWeb ? `w-[211px] h-[215px]` : ''}`}
        source={ImageEleven}
        alt={'Page Nine Image'}
        resizeMethod='resize'
        resizeMode='contain' />
      
      <Text style={tw`mb-1.25 text-center text-xl uppercase text-primary-dark font-secondaryBold font-spacing`}>Check The Receipt Before Submitting</Text>
      
      <Text style={tw`text-gray-600 text-left font-spacing-sm`}>
        <Text style={tw`text-gray-600 font-spacing-sm font-bold`}>
          {`Your receipt must contain the name of the alcohol brand `}
        </Text>
        as a line item on the reciept. Some retailers may use a line item description such as "BEER."
        Unfortunately, generic product names are not acceptable.
      </Text>
    </>
  }

  function PageSixSignedIn() {
    return <>
      <Text style={tw`mb-sm text-left text-4.5xl text-black font-bold font-spacing-md`}>How to Redeem (6/6)</Text>
      
      <Image style={tw`mx-auto mb-1.25 ${isWeb ? `w-[208px] h-[205px]` : ''}`}
        source={ImageTwelve}
        alt={'Page Ten Image'}
        resizeMethod='resize'
        resizeMode='contain' />
      
      <Text style={tw`mb-1.25 text-center text-xl uppercase text-primary-dark font-secondaryBold font-spacing`}>Reciept Requirements Include</Text>
      <View style={tw``}>
        <FlatList
            data={[
              {key: 'Store Name'},
              {key: 'Store Address'},
              {key: 'Date of Purchase'},
              {key: 'Product Name'},
              {key: 'Product Price'},
              {key: 'Total Purchase Price'},
              {key: 'Transaction ID'}
            ]}
            renderItem={({item}) => <Text style={tw`text-left text-gray-600 font-spacing-sm font-bold`}>-{item.key}</Text>}/>
      </View>
    </>
  }

  function XIcon(){
  return <Svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="black" className="w-6 h-6">
    <Path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
  </Svg>
  }
}
