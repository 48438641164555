import React, {useContext} from 'react';
import {View, Text, ScrollView, KeyboardAvoidingView} from 'react-native';
import {LinearGradient} from 'expo-linear-gradient';
import {pSBC} from 'js-utilities';

import {BlurView} from '../../assets/functions/objects';

import Context from '../context.jsx';


export default function Page({header, children, bottomContent, maxWidth}) {
  const {tw, headerHeight, contentHeight, isIos, isDesktopWeb, menuOpen, currentGradient, handleScroll, swipableViewProps} = useContext(Context);

  const maxWidthMap = {
    'normal': 'max-w-192',
    'wide': 'max-w-320',
    'full': 'max-w-full'}

  const maxWidthClass = maxWidthMap[maxWidth] || maxWidthMap['normal'];

  return <LinearGradient style={tw`h-[${headerHeight + contentHeight}px]`}
    colors={currentGradient} start={{x: 0.10, y: 0.10}} end={{x: 0.9, y: 0.9}}
    {...(menuOpen ? {} : swipableViewProps)}>

    <LinearGradient colors={['rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0.36)']}
      style={tw`absolute w-full h-full`}
      start={{x: 0.5, y: 0.5}}
      end={{x: 1, y: 1}} />

    <KeyboardAvoidingView style={tw`flex-1`}
      behavior={isIos ? 'padding' : 'height'}>
        <ScrollView contentContainerStyle={tw`items-center px-base ${isIos ? 'pb-xl' : 'pb-base'} pt-[${headerHeight + tw`pt-base`.paddingTop}px]`}
          onScroll={handleScroll}
          scrollEventThrottle={16}>
          <View style={tw`w-full h-full ${maxWidthClass}`}>
            {header ? <View style={tw`flex-row justify-center items-center w-full px-base mb-base`}>
              {typeof header === 'string' ? <Text style={tw.style(`text-8xl text-center text-white font-primaryMedium font-spacing-lg`, {
                textShadowColor: tw`text-light-light`.color,
                textShadowRadius: 1
              })}>{header}</Text> : header}
            </View> : null}

            <View style={tw`w-full right-0.5xs`}>
              <View style={tw.style(`absolute justify-end top-xs left-xs w-full h-full rounded-sm bg-white bg-opacity-60 shadow overflow-hidden`)}>
                {isDesktopWeb ? null : <BlurView style={tw.style(`w-full h-full rounded-sm`)}
                  opacity={0.75}
                  blurAmount={24}
                  blurType={'light'} />}

                <LinearGradient colors={[currentGradient[0], pSBC(-0.5, currentGradient[1])].reverse()}
                  style={tw.style(`w-full h-full rounded-sm`, {
                    opacity: 0.64})}
                  start={{x: 0.5, y: 0.1}}
                  end={{x: 0.5, y: 0.9}}
                  locations={[0, 1]} />
              </View>

              <View style={tw`w-full items-center p-base rounded-sm bg-white shadow`}>
                {children}
              </View>
            </View>

            {bottomContent ? <View style={tw.style(`items-center w-full pt-1.5lg px-base`)}>
              {bottomContent}
            </View> : null}
          </View>
        </ScrollView>
    </KeyboardAvoidingView>
  </LinearGradient>;
}
