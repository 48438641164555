import React, {useContext} from 'react'
import {View, Text, Pressable} from 'react-native';

import {useNavigate} from './utilities/router.jsx';
import Context from './context.jsx';
import Page from './ui/page.jsx';

export default function LocationPage() {
  const {tw, usStates, currentGradient,
    selectedState, setSelectedState, previousRouterLocation, afterLocationPath} = useContext(Context)
  const navigate = useNavigate()

  return <Page header={'Choose Your Location'} bottomContent={<View style={tw`justify-center`}>
      <Text style={tw`max-w-96 text-center text-white font-primaryMedium font-spacing-sm`}>
        Enable location services for an improved experience.
        BYBE does not collect non-essential user information and we will never share your data with third parties.
      </Text>
    </View>}>

    <View style={tw`flex-row flex-wrap items-center justify-center`}>
      {Object.values(usStates).map(state => {
        const selected = selectedState && selectedState.name === state.name;
        return <Pressable key={`state-${state.abbreviation}`}
          style={tw.style(`py-xs px-1.5xs m-1 border-t border-l border-light-light rounded-full capitalize shadow ${selected ? `bg-[${currentGradient[0]}]` : 'bg-white'}`)}
          onPress={() => {
            setSelectedState(state)
            navigate(afterLocationPath)
          }}>

          <Text style={tw`text-base font-primary font-spacing-sm ${selected ? ' text-white' : ''}`}>{state.name}</Text>
        </Pressable>
      })}
    </View>
  </Page>;
}
