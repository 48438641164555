export default function parseNestedErrors(entryKey) {
	const fullErrors = []
	parseCurrentValue(entryKey)
	
	function parseCurrentValue(currentValue, trail=[]) {
		let errors = []
		if (Array.isArray(currentValue))
			return fullErrors.push(
				{trail: [], errors: currentValue})
		
		for (let key in currentValue) {
			let innerValue = currentValue[key]
			trail.push(key)
			
			if (Array.isArray(innerValue)) {
				errors.push(...innerValue)
				fullErrors.push({...{
					trail: [...trail],
					errors: [...errors]}})
				
				trail.pop()
				errors = []}
			else if (Object.keys(innerValue).length)
				parseCurrentValue(innerValue, trail)}}
	return fullErrors}

// PSEUDO CODE
// 1 loop over keys
	// 2 for each key, determine if value is array or object
		// array
			// 3 determine the trail of keys to get to the array
			// 4 Make an object with 2 keys - trail (array of strings) and errors (array of strings)
		// object
			// go to 1 and continue for new object

const test = {"user": {
	"phone_number": [
		"has already been taken",
		"is not valid"],
	"email": [
		"has already been taken"],
	"media_uploads": {
		"transaction_number": [
			"is invalid",
			"has already been used"]}}}

const expectedReturnValue = [{"trail": [
	"user",
	"phone_number"],
"errors": [
	"has already been taken",
	"is not valid"]},
{"trail": [
	"user",
	"email"],
"errors": [
	"has already been taken"]},
{"trail": [
	"user",
	"media_uploads",
	"transaction_number"],
"errors": [
	"is invalid",
	"has already been used"]}]
